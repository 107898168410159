import React, {useContext, useEffect, useState} from "react";
import {IonButton, IonContent, IonPage, IonTabButton} from "@ionic/react";
import "./Discover.scss";
import useUser from '../../store/useUser';
import HeaderMain from '../../components/Header/HeaderMain';
import JobMatchCardStore from '../../components/Vibecard/JobMatchCardStore';
import JobProfile, {EMatchState} from '../../model/JobProfile';
import {TabContext} from '../Tabs/Tabs';

import {StatusBar, Style} from '@capacitor/status-bar';
import {Link} from "react-router-dom";


export interface JobProfileId {
    _id: string;
}

export interface JobSeeker {
    _id: string;
}

export interface JobNameLink {
    _id: string;
    link: string;
    display: string;
}

export interface JobSector {
    _id: string;
    link: string;
    display: string;
}

export interface JobImage {
    path: string;
}

export interface JobTrait {
    _id: string;
    link: string;
    display: string;
}

export interface CompanyLink {
    _id: string;
    link: string;
    display: string;
}

export interface Traits {
}

export interface IJobProfile {
    jobNameLink: JobNameLink;
    active: boolean;
    jobTitle: string;
    jobType: string[];
    jobSector: JobSector;
    jobDepartment: string;
    jobImage: JobImage;
    jobIncome: string;
    jobExperience: string[];
    jobFeatures: string;
    jobRequirements: string;
    jobLocation: string;
    jobTraits: JobTrait[];
    jobAnsprechpartner: string;
    company: CompanyLink;
    matchData: string;
    _mby: string;
    _by: string;
    _modified: number;
    _created: number;
    _id: string;
    traits: Traits;
    jobDescription: string;
}

export interface User {
    _id: string;
    link: string;
    display: string;
}

export interface Company {
    name: string;
    logoUrl: string;
    street: string;
    streetNumber: string;
    postcode: string;
    isActive: boolean;
    _mby: string;
    _by: string;
    _modified: number;
    _created: number;
    _id: string;
    city: string;
    email: string;
    subscription: string[];
    IBAN?: any;
    user: User;
}

export interface UserLink {
    _id: string;
}

export interface JobSeeker {
    careerStartDate: string;
    jobType: string;
    firstName: string;
    lastName: string;
    birthdate: string;
    phone: string;
    careerCurrentExperience: string;
    careerCurrentDegree: string;
    brancheCurrent: string;
    seekTraits: string[];
    seekerTraits: string[];
    user: UserLink;
    _by?: any;
    _modified: number;
    email: string;
    additionalName?: any;
    branch?: any;
    street: string;
    streetNumber: string;
    postcode: string;
    city: string;
    country: string;
    _created: number;
    _id: string;
    _mby: string;
    lastJobTitle: string;
    degreeCurrent: string;
    hasFinishedWizzard: Date;
}

export interface IJobMatch {
    jobProfile: JobProfile;
    company: Company;
    matchDate: string;
    matchStateSeeker: string;
    jobSeeker: JobSeeker;
    _by?: any;
    _modified: number;
    matchStateCompany?: any;
    chat?: any;
    _created: number;
    _id: string;
}

let lastLoaded: Date

const Discover: React.FC = ({}) => {
    const {visible, setVisible} = useContext(TabContext)
    const matches = useUser(state => state.matches)
    const canceledMatches = useUser(state => state.canceledMatches)
    const interestingMatches = useUser(state => state.interestingMatches)
    const hotMatches = useUser(state => state.hotMatches)
    const waitingMatches = useUser(state => state.waitingMatches)
    const storedMatches = useUser(state => state.storedMatches)



    const loadMatches = useUser(state => state.loadMatches)

    const [isActive, setActive] = useState(true);

    const flag = Math.random()

    useEffect(() => {
            if (!lastLoaded) {
                loadMatches()
                lastLoaded = new Date()
                return
            }

            if (lastLoaded) {
                const diff = new Date().getTime() - lastLoaded.getTime()
                if (diff < 1000 * 25) {
                    return
                }
                loadMatches()
                lastLoaded = new Date()
            }

        },
        [flag, loadMatches]
    )

    const handleStart = async (actnum: any) => {

        if (isActive) {
            setVisible(false)
            setActive(false);
            await StatusBar.show();
        } else {
            setActive(true)
            setVisible(true)
            await StatusBar.hide();
        }

    };

    //console.log({hotMatches})
    return (
        <IonPage>

            {isActive && <HeaderMain title={"Matches"}/>}
            <IonContent className={`discover-page ion-padding `}>


                {matches.length === 0 && <div className="flex flex-col items-center justify-center h-full">
					<div>Keine Matches</div>

					<Link to={"/tabs/explore"}>
						<IonButton>
							Finde Stellen
                            {/*<img className="tab-icon-inactive" src="assets/img/logo_small_bw.png" width="24" alt="" />*/}
                            {/*<img className="tab-icon-active" src="assets/img/logo_small.png" width="24" alt="" />*/}
						</IonButton>
					</Link>
				</div>}

                {/*HOT*/}
                {hotMatches.length > 0 && <div className="pb-4">
					<div className="uppercase text-sm text-gray-600">Matches</div>
					<div>
                        {hotMatches.map(match => {
                            return <JobMatchCardStore
                                key={match._id}
                                onToggled={() => handleStart(match._id)}
                                match={match}/>
                        })}

					</div>
				</div>}


                {/*WARTELISTE*/}
                {waitingMatches.length > 0 && <div className="pb-4">
					<div className="uppercase text-sm text-gray-600">Ausstehende Bewerbungen</div>
					<div>
                        {waitingMatches.map(match => {
                            return <JobMatchCardStore
                                key={match._id}
                                onToggled={() => handleStart(match._id)}
                                match={match}/>
                        })}
					</div>
				</div>}


                {storedMatches.length > 0 && <div className="pb-4">
                    {/*MERKLISTE*/}
					<div className="uppercase text-sm text-gray-600">Merkliste</div>
                    {storedMatches.map(match => {
                        return <JobMatchCardStore
                            key={match._id}
                            onToggled={() => handleStart(match._id)}
                            match={match}/>
                    })}
				</div>}


                {/*CANCELED*/}
                {canceledMatches.length > 0 && <div className="pb-4">
					<div className="uppercase text-sm text-gray-600">Abgelehnte Bewerbungen</div>
					<div>

                        {canceledMatches.map(match => {
                            return <JobMatchCardStore
                                key={match._id}
                                onToggled={() => handleStart(match._id)}
                                match={match}/>
                        })}

					</div>
				</div>}


            </IonContent>
        </IonPage>
    );
};

Discover.defaultProps = {};

export default Discover;
