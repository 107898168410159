import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import "./index.css"
import { defineCustomElements } from '@ionic/pwa-elements/loader';
// import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
//
// // use hook after platform dom ready
// GoogleAuth.initialize({
//     clientId: '619018202971-f34e00ig9hpdgbtufh0rui9ufomlu31j.apps.googleusercontent.com',
//     scopes: ['profile', 'email'],
//     grantOfflineAccess: true,
// });
// Use matchMedia to check the user preference
const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

toggleDarkTheme(prefersDark.matches);

// Listen for changes to the prefers-color-scheme media query
prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

// Add or remove the "dark" class based on if the media query matches
function toggleDarkTheme(shouldAdd: boolean) {
    document.body.classList.toggle('dark', shouldAdd);
}

declare let global: {
    BASE_URL: string
}
global.BASE_URL = process.env.REACT_APP_API || "https://skilledapp.de/"
//console.log("API", global.BASE_URL)

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

defineCustomElements(window);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();