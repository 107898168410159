import React, { useEffect, useState } from "react";
import { IonButton, IonContent, IonIcon, IonInput, IonPage, IonSpinner, IonToast, } from "@ionic/react";
import { mail } from "ionicons/icons";
import "./Landing.scss";
import { motion } from "framer-motion";
import useUser from 'store/useUser';
import api from '../../store/api';
import notifications from '../../components/Notifications/Notifications';

type Props = {
    history: any;
};

const Landing: React.FC<Props> = ({ history })=>{
    const login = useUser(state=>state.login)
    const stepData = useUser(state=>state.stepData)
    const session = useUser(state=>state.session)
    const [ isLoading, setIsLoading ] = useState(false);
    const [ toastMessage, setToastMessage ] = useState<string | false>("")

    const [ email, setEmail ] = useState(localStorage.getItem("clientMail"))
    const [ clientToken, setClientToken ] = useState<any>(localStorage.getItem("clientToken"))
    const [ credentials, setCredentials ] = useState<any>(false)

    const tryLogin = async(event: any)=>{
        event.preventDefault()
        if ( !email ) return
        if ( isLoading ) return
        setIsLoading(true);
        setToastMessage("Klicke den Link in Deiner E-Mail um fortzufahren.")
        if ( isLoading ) return

        const promise = await api<{ clientToken: string }>(`/api/auth/requestToken`, {
            mode: "cors",
            data: {
                email,
            },
            method: "POST"
        })
        const { clientToken } = promise


        setCredentials({ clientToken, email })
        // await login(email, clientToken)
        // setIsLoading(false);
    };

    const resetLogin = ()=>{
        localStorage.setItem("clientMail", "")
        localStorage.setItem("clientToken", "")
    }

    const finishLogin = async(email: string, pass: string)=>{
        localStorage.setItem("clientMail", email)
        localStorage.setItem("clientToken", pass)

        try {
            let res = await login(email, pass)
            //console.log("SUCCESS:LOGIN", res)
            setIsLoading(false)
        } catch ( err ) {
            setCredentials(false)
            setClientToken(false)
            notifications.toast.error("Session abgelaufen")
        }


    }

    useEffect(()=>{
        async function verifyToken() {
            //console.log("VERIFY START")
            const promise = await api<{ status: boolean }>(`/api/auth/verifyToken?clientToken=${ credentials.clientToken }&email=${ credentials.email }`,)
            //console.log(promise.status, { promise })
            if ( promise.status ) {
                // YAY
                //console.log("VERIFY SUCCESS")
                await finishLogin(credentials.email, credentials.clientToken)
            }
            if ( !promise.status ) {
                // TODO: EXP. BACKOFF after 4-5 minutes
                // TODO: WEBSOCKETS
                setTimeout(verifyToken, 8000)
            }
        }

        if ( credentials ) {
            setIsLoading(true)
            if ( credentials.email && credentials.clientToken ) setTimeout(()=>verifyToken(), 3000)
        }
    }, [ credentials ])


    useEffect(()=>{
        if ( session ) {
            if ( stepData ) {
                if ( stepData.hasFinishedWizzard ) history.replace("/tabs/explore");
                if ( !stepData.hasFinishedWizzard ) history.replace(`/onboarding/${ session.primarySeekerId }`);
            }
        }
    }, [ session, stepData ])

    const waitForLogin = ( toastMessage || ( email && clientToken ) )
    return (
        <IonPage>
            <IonContent forceOverscroll={ false } className="landing-page bg-gradient">
                { toastMessage && <IonToast
                  isOpen={ true }
                  position={ "middle" }
                  onDidDismiss={ ()=>setToastMessage("") }
                  message={ toastMessage }
                /> }
                <div className="vertical-layout safe-area-bottom">
                    <div className="section-brand">
                        <motion.div className="logo" animate={ { scale: [ 1, 1.5, 1 ] } } transition={ { duration: 1 } }>
                            <img
                                className="logo-icon"
                                src="assets/img/logofull.svg"
                                alt=""
                            />
                        </motion.div>
                    </div>

                    <div className="section-buttons ion-padding">

                        <div className="social-btns-holder">
                            {/*<IonButton*/ }
                            {/*    expand="block"*/ }
                            {/*    fill="outline"*/ }
                            {/*    color="white"*/ }
                            {/*    shape="round"*/ }
                            {/*    className="button-facebook button-landing"*/ }
                            {/*    onClick={ tryLogin }*/ }
                            {/*>*/ }
                            {/*    <IonIcon slot="start" icon={ logoApple } size="small"/>*/ }
                            {/*    Apple*/ }
                            {/*</IonButton>*/ }

                            {/*<IonButton*/ }
                            {/*    expand="block"*/ }
                            {/*    fill="outline"*/ }
                            {/*    color="white"*/ }
                            {/*    shape="round"*/ }
                            {/*    className="button-facebook button-landing"*/ }
                            {/*    onClick={ async()=>{*/ }
                            {/*        // let googleUser = await GoogleAuth.signIn();*/ }
                            {/*        // console.log({ googleUser })*/ }
                            {/*        // if ( googleUser ) {*/ }
                            {/*        //*/ }
                            {/*        // }*/ }
                            {/*    } }*/ }
                            {/*>*/ }
                            {/*    <IonIcon slot="start" icon={ logoGoogle } size="small"/>*/ }
                            {/*    Google*/ }
                            {/*</IonButton>*/ }

                            {/*<IonButton*/ }
                            {/*    expand="block"*/ }
                            {/*    fill="outline"*/ }
                            {/*    color="white"*/ }
                            {/*    shape="round"*/ }
                            {/*    className="button-facebook button-landing"*/ }
                            {/*    onClick={ tryLogin }*/ }
                            {/*>*/ }
                            {/*    <IonIcon slot="start" icon={ logoFacebook } size="small"/>*/ }
                            {/*    Facebook*/ }
                            {/*</IonButton>*/ }

                            { waitForLogin && <div>
                              <div className="text-sm pb-2">Weiter mit Deinem Konto</div>
                              <IonButton
                                fill="outline"
                                expand="block"
                                shape="round"
                                className=" "
                                onClick={ ()=>{
                                    if ( isLoading ) return
                                    email && finishLogin(email, clientToken)
                                }
                                }
                              >
                                <IonIcon slot="start" icon={ mail } size="small"/>
                                  { isLoading && <IonSpinner/> }
                                  { !isLoading && <span>{ email }</span> }

                              </IonButton>
                              
                              <div>
                                <IonButton
                                  fill="outline"
                                  expand="block"
                                  shape="round"
                                  className=" "
                                  onClick={ ()=>{
                                      setEmail("")
                                      setClientToken("")
                                      setCredentials(false)
                                  } }
                                >
                                  <IonIcon slot="start" icon={ mail } size="small"/>
                                  Konto wechseln
                                </IonButton>
                              </div>
                            </div> }

                            { !waitForLogin && <form onSubmit={ tryLogin }>
                              
                              <IonInput
                                value={ email }
                                onIonChange={ (e)=>{
                                    const email = `${ e.target.value }`
                                    setEmail(email)
                                } }
                                autocomplete="email"
                                type="email"
                                placeholder="E-Mail"/>
                              
                              <IonButton
                                fill="outline"
                                expand="block"
                                shape="round"
                                className=" ion-margin-bottom"
                                onClick={ tryLogin }
                              >
                                <IonIcon slot="start" icon={ mail } size="small"/>
                                Los geht's!
                              </IonButton>
                            </form> }

                        </div>

                        {/*<IonButton fill="clear" color="white" className="button-clear">*/ }
                        {/*  Trouble logging in?*/ }
                        {/*</IonButton>*/ }
                    </div>
                </div>
            </IonContent>


        </IonPage>
    );
};

Landing.defaultProps = {};

export default Landing;
