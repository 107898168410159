import React, {useState} from "react";
import "./Vibecard.scss";
import {motion, useCycle,} from "framer-motion";
import {IonButton, IonFab, IonIcon, IonModal} from '@ionic/react';
import {arrowDown} from 'ionicons/icons';
import VibeChat from './VibeChat';
import {IJobMatch} from 'pages/Discover/Discover';
import {EMatchState} from 'model/JobProfile';
import Profile from 'pages/Profile/Profile';
import useUser from "store/useUser";
import useJobMatch from "store/useJobMatch";

type Props = {
    onToggled: () => void;
    match: IJobMatch
};

const JobMatchCardStore: React.FC<Props> = ({match, onToggled}) => {
    const events: any[] = []

    const [showJobProfile, setShowJobProfile] = useState(false)
    const [isActivee, setActivee] = useState(false);


    const jobs = useUser.getState().jobProfiles
    const createMatch = useJobMatch.getState().createMatch


    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height,
        };
    };

    const variants = {
        open: {
            zIndex: 1,
            y: 0,
            height: 0,
            background: "#FFF",
        },
        collapsed: {
            background: "#FFF",
            zIndex: 1002,
            top: 0,
            height: getWindowDimensions().height,
            margin: 0,
            transition: {duration: 0.1},
        },
    };

    const [variant, toggleVariant] = useCycle("open", "collapsed");

    const handleTap = () => {
        toggleVariant();
        onToggled()
        if (isActivee === false) {
            setActivee(true);
        } else {
            setActivee(false)
        }
    };

    isActivee && console.log({match})

    const isStored = match.matchStateSeeker === "STORED"
    const isChatMode = (match.matchStateCompany === EMatchState.REQUESTED || match.matchStateCompany === EMatchState.CONTACTED)

    // @ts-ignore
    const jobSeekerId = useUser.getState().session.primarySeekerId
    const jobProfileId = match.jobProfile._id

    let stateButton = null
    if (match.matchStateSeeker === "REQUESTED") stateButton = <IonButton size={"small"} color={"yellow"} fill={"outline"}>Beworben</IonButton>
    if (match.matchStateCompany === "REQUESTED") stateButton = <IonButton size={"small"} color={"yellow"} fill={"outline"}>Match</IonButton>
    if (match.matchStateCompany === "CONTACTED") stateButton = <IonButton size={"small"} color={"green"} fill={"outline"}>Kontakt</IonButton>
    if (match.matchStateCompany === "CANCELED") stateButton = <IonButton size={"small"} color={"white"} fill={"outline"}>Abgelehnt</IonButton>
    if (match.matchStateCompany === "REJECTED") stateButton = <IonButton size={"small"} color={"white"} fill={"outline"}>Abgelehnt</IonButton>

    if (!match.company) return <div>Firma nicht mehr vorhanden</div>

    const placeholderSrc = "url('placeholder-event.jpg')"
    return (
        <div>

            <IonModal isOpen={showJobProfile} canDismiss>
                <Profile
                    user={match.jobProfile}
                    onClose={() => {
                        setShowJobProfile(false)
                        //console.log("ON CLOSE")
                    }}
                />
            </IonModal>


            <motion.div className={`wrapper-card ${variant}`} variants={variants} animate={variant}>
                <motion.div
                    onClick={() => handleTap()}
                    className="relative bg-gray-900 rounded-lg bg-cover bg-center bg-no-repeat"
                    animate={{scale: 1.02}}

                >
                    <div className="absolute left-0 right-0 bottom-0 top-0 rounded-lg bg-cover bg-center bg-no-repeat z-[-1] opacity-40"
                         style={{backgroundImage: match.company.logoUrl ? `url('${process.env.REACT_APP_API}${match.company.logoUrl}')` : placeholderSrc}}>

                    </div>
                    <div className="bgg p-4 z-100">
                        <div className="flex flex-row justify-between">
                            <div className="text text-white  ">
                                <motion.h1>{match.jobProfile.jobTitle}</motion.h1>
                            </div>
                            <div className="ml-auto flex flex-col justify-end">
                                {stateButton}
                            </div>

                        </div>

                        <div className="absolute bottom-0 left-0 p-4">
                            <div className="caption-title">{match.company.name}</div>
                            <div className="text-sm text-white">{match.company.postcode} {match.company.city}</div>
                        </div>
                        <IonFab vertical="bottom" horizontal="end" edge slot="fixed">
                            <motion.div
                                initial={false}
                                transition={{delay: 0.25}}
                                animate={{opacity: isActivee ? 1 : 0, y: isActivee ? 0 : -20}}
                            >

                                <IonButton
                                    color="white"
                                    className=""
                                >
                                    <IonIcon icon={arrowDown} slot="icon-only"/>
                                </IonButton>

                            </motion.div>
                        </IonFab>

                    </div>
                </motion.div>

                {isActivee &&
					<div className="text-wrapper ion-padding">

						<div><IonButton size="small" onClick={() => {
                            setShowJobProfile(true)
                        }}>Job anzeigen</IonButton></div>

                        {!isChatMode && <>
							<div className="text-xl font-bold pb-4">Wie geht es weiter?</div>
							<div className="">
								<div className="bg-green-600 rounded-lg p-4 text-white">
									<div className="flex flex-row items-center">
										<div className="text-4xl w-8 flex flex-row justify-center font-bold mr-2">
											<div>1</div>
										</div>
										<div>
											<div className={"font-bold flex flex-row items-center"}>Skilled App</div>
											<div className="text-xs">Die Stelle wurde Dir vorgeschlagen.</div>
										</div>
									</div>
								</div>

                                {isStored && <div className="border shadow-sm rounded-lg p-4 mt-2">
									<div className="flex flex-row items-center">
										<div className="text-4xl w-8 flex flex-row justify-center font-bold mr-2">
											<div>2</div>
										</div>
										<div>
											<div className={"font-bold flex flex-row items-center"}>Merkliste</div>
											<div className="text-xs">Du hast diesen Job auf Deine Merkliste gesetzt</div>
										</div>
									</div>
								</div>}

                                {!isStored && <>
                                    {match.matchStateSeeker !== "STORED" && <div className="bg-green-600 rounded-lg p-4 text-white mt-2">
										<div className="flex flex-row items-center">
											<div className="text-4xl w-8 flex flex-row justify-center font-bold mr-2">
												<div>2</div>
											</div>
											<div>
												<div className={"font-bold flex flex-row items-center"}>Dein Match</div>
												<div className="text-xs">Du hast geswiped.</div>
											</div>
										</div>
									</div>}

                                    {(match.matchStateCompany === "REQUESTED" || match.matchStateCompany === "CONTACTED") && <div>
										<div className="bg-green-600 rounded-lg p-4 text-white mt-2">
											<div className="flex flex-row items-center">
												<div className="text-4xl w-8 flex flex-row justify-center font-bold mr-2">
													<div>3</div>
												</div>
												<div>
													<div className={"font-bold flex flex-row items-center"}>{match.company.name}</div>
													<div className="text-xs">Die Firma möchte Dich kennenlernen</div>
												</div>
											</div>
										</div>
									</div>
                                    }

                                    {!match.matchStateCompany && <div>
										<div className="border shadow-sm rounded-lg p-4 mt-2">
											<div className="flex flex-row items-center">
												<div className="text-4xl w-8 flex flex-row justify-center font-bold mr-2">
													<div>3</div>
												</div>
												<div>
													<div className={"font-bold flex flex-row items-center"}>{match.company.name}</div>
													<div className="text-xs">Dein Match wartet auf die Firma.</div>
												</div>
											</div>
										</div>
									</div>}

                                    {match.matchStateCompany !== "CANCELED" && <div className="border shadow-sm rounded-lg p-4 mt-2">
										<div className="flex flex-row items-center">
											<div className="text-4xl w-8 flex flex-row justify-center font-bold mr-2">
												<div>4</div>
											</div>
											<div>
												<div className={"font-bold flex flex-row items-center"}>Chat</div>
												<div className="text-xs">Chatte mit Deinem persönlichen Ansprechpartner.</div>
											</div>
										</div>
									</div>
                                    }
								</>
                                }
							</div>

                            {isStored && <div className="absolute bottom-0 left-0 right-0 p-4">
								<div className="flex flex-row items-center justify-between">
									<IonButton
										onClick={() => {
                                            createMatch(jobSeekerId, jobProfileId, EMatchState.TRASHED)
                                        }}
										fill={"outline"} color={"red"}>Ablehnen</IonButton>
									<IonButton
										onClick={() => {
                                            createMatch(jobSeekerId, jobProfileId, EMatchState.REQUESTED)
                                        }}
									>Matchen</IonButton>
								</div>
							</div>}

							<div className="mt-2 mb-4">
                                {/*KEIN MATCH*/}
                                {(!isStored && !match.matchStateCompany) && <div className={"prose prose-sm pre text-center"}>
									<div>Wie geht es weiter?</div>
									<div className={"text-xs text-gray-600"}>
										<p>Der Arbeitgeber bestätigt Euer Match innerhalb von 48 Stunden</p>
									</div>
								</div>}

                                {/*MATCH*/}
                                {match.matchStateCompany === EMatchState.REQUESTED && <div className={""}>
									<div className="text-sm uppercase text-gray-600">Ihr Ansprechpartner</div>
									<div className="text-xs">
                                        {match.jobProfile.jobAnsprechpartner || "Kein Ansprechpartner - sprechen Sie uns im Chat an."}
									</div>
								</div>}

                                {(match.matchStateCompany === EMatchState.CANCELED || match.matchStateCompany === EMatchState.REJECTED) && <div className="bg-red-600 rounded-xl text-white">
									<div className={"text-center text-white py-4 text-sm"}>
										<span className="font-bold">
                                            Deine Anfrage wurde nicht bestätigt.
                                        </span><br/> Viel Erfolg beim nächsten Match!
									</div>
                                </div>}
							</div>
						</>
                        }

                        {isChatMode && <>
                            {/*<div className="text-2x">A) MELDE DICH BEI ANSPRECHPARTNER</div>*/}
                            {/*<div className="text-2x">B) PASST DER TERMIN? (oder melde Dich bei Ansprechpartner)</div>*/}
                            {/*   <div*/}
                            {/*     className={ "bg-gray-200 p-4 text-xs prose whitespace-pre" }*/}
                            {/*   >*/}
                            {/*       {match.jobProfile.jobAnsprechpartner}*/}
                            {/*   </div>*/}
							<VibeChat match={match}/>
						</>}
					</div>

                }

            </motion.div>

            {/*THIS IS VITAL, DON'T REMOVE => SPACES*/
            }
            <motion.div className="spacer test-card" onClick={() => {
                handleTap()
            }}>

            </motion.div>
        </div>
    )
        ;
};

export default JobMatchCardStore;
