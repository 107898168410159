import React from "react";
import {IonButton, IonContent, IonFab, IonHeader, IonIcon, IonToolbar,} from "@ionic/react";
import {arrowDown, briefcaseOutline, clipboardSharp, locationOutline,} from "ionicons/icons";
import ProfileImageSlides from "../../components/ProfileImageSlides/ProfileImageSlides";
import "./Profile.scss";
import JobProfile from '../../model/JobProfile';
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import currency from '../../store/lib/currency';
import useUser from '../../store/useUser';

type Props = {
    user: any;
    onClose: () => void;
};

const Profile: React.FC<Props> = ({user, onClose}) => {
    const job: JobProfile = user
    //console.log("DETAIL", { job })
    if (!user) return null;
    return (
        <>
            {/*<IonHeader>*/}
            {/*    <IonToolbar*/}
            {/*        className="toolbar-reduced toolbar-no-border"*/}
            {/*        color="light"*/}
            {/*    />*/}
            {/*</IonHeader>*/}

            <IonContent className="profile-page ">
                <div className="profile-header">
                    <ProfileImageSlides images={job.images}/>


                    <div className="absolute text-white right-0 left-0 top-0 bottom-0 flex flex-row items-center justify-around z-20">
                        <div className="text-center">
                            <div className="info-item job-type">
                                {job.jobType?.join(", ")}
                            </div>
                            <div className={"text-2xl font-bold ph-4"}>{job.jobTitle}</div>
                            <div className="profile-user-info">
                                <div className="info-item job-sector">
                                    {job.jobSectorName} {job.jobDepartment ? " / " + job.jobDepartment : ""}
                                </div>
                            </div>
                        </div>

                    </div>
                    <IonFab vertical="bottom" horizontal="end" edge slot="fixed">
                        <IonButton
                            color="white"
                            className=""
                            onClick={onClose}
                        >
                            <IonIcon icon={arrowDown} slot="icon-only"/>
                        </IonButton>

                    </IonFab>
                </div>


                <div className="bg-gray-100">
                    {job.matchingScore.score && <div className="p-4 border-bottom">
						<div className="text-sm uppercase text-gray-600 pb-2">Dein Matching-Score</div>
						<div className="flex flex-col items-center p-4 bg-white rounded shadow-sm">
							<div className="w-[90px] ">
								<CircularProgressbarWithChildren
									value={Number(job.matchingScore.score)}
									circleRatio={0.75}
									strokeWidth={12}
									styles={buildStyles({
                                        rotation: 1 / 2 + 1 / 8,
                                        strokeLinecap: "round",
                                        trailColor: "#686d76",
                                        pathColor: job.matchingScore.color,
                                        textSize: "5px",
                                    })}
								>
									<div className="text-3xl font-bold">{job.matchingScore.score}</div>
								</CircularProgressbarWithChildren>
							</div>
						</div>

					</div>}


                    {/*<div className="p-4">*/}
                    {/*    <div className="text-sm uppercase text-gray-600 pb-2">Deine Aufgaben</div>*/}
                    {/*    <div className="prose" dangerouslySetInnerHTML={{__html:job.jobDescription}}/>*/}
                    {/*</div>*/}

                    <div className="p-4 border-bottom">
                        <div className="text-sm uppercase text-gray-600 pb-2">Deine Aufgaben</div>
                        <div className="prose" dangerouslySetInnerHTML={{__html: job.jobFeatures}}/>
                    </div>

                    <div className="p-4 border-bottom">
                        <div className="text-sm uppercase text-gray-600 pb-2">Dein Profil</div>
                        <div className="prose" dangerouslySetInnerHTML={{__html: job.jobRequirements}}/>
                    </div>


                    {Object.keys(job.traits).length > 0 &&
						<div className="p-4 border-bottom">
							<div className="text-sm uppercase text-gray-600 pb-2">Deine Vorteile</div>
							<div className="passion-list">
                                {Object.keys(job.traits).map((trait) => {
                                    return <IonButton key={trait} fill="outline" color="medium" size="small">
                                        {trait}
                                    </IonButton>
                                })}
							</div>
						</div>}

                    {job.jobIncome && <div className="p-4 border-bottom">
						<div className="text-sm uppercase text-gray-600 pb-2">Dein Gehalt</div>
						~{currency(job.jobIncome)} monatl.
					</div>}

                    <div className="p-4">
                        <IonButton
                            onClick={onClose}
                            fill={"outline"} expand={"block"}>
                            Zurück
                        </IonButton>
                    </div>

                    {/*<IonFab*/}
                    {/*    className="bottom-actions"*/}
                    {/*    vertical="bottom"*/}
                    {/*    horizontal="center"*/}
                    {/*    slot="fixed"*/}
                    {/*>*/}
                    {/*    <IonButton*/}
                    {/*        color="white"*/}
                    {/*        className="button-custom button-icon button-dislike button-lg"*/}
                    {/*    >*/}
                    {/*      NEIN*/}
                    {/*    </IonButton>*/}
                    {/*    <IonButton*/}
                    {/*        color="white"*/}
                    {/*        className="button-custom button-icon button-dislike button-lg"*/}
                    {/*    >*/}
                    {/*        <IonIcon slot="icon-only" icon={ clipboardSharp }/>*/}
                    {/*    </IonButton>*/}
                    {/*    <IonButton*/}
                    {/*        color="white"*/}
                    {/*        className="button-custom button-icon button-like button-lg"*/}
                    {/*    >*/}
                    {/*      JA*/}
                    {/*    </IonButton>*/}
                    {/*</IonFab>*/}
                </div>
            </IonContent>
        </>
    );
};

Profile.defaultProps = {};

export default Profile;
