import React from 'react';
import {useSwiper} from 'swiper/react';
import {FormInput, SlideContent} from '../Onboarding';
import useUser from '../../../store/useUser';
import {UploadItem} from "pages/ProfileEdit/ProfileEdit";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {IonInput, IonItem, IonLabel} from "@ionic/react";

export function FormPhoneInput() {
    // `value` will be the parsed phone number in E.164 format.
    // Example: "+12133734253".
    const setStepData = useUser(state => state.setStepData)
    const stepData = useUser(state => state.stepData)
    return (
        <IonItem className="ion-no-padding" >
            <PhoneInput
                className="PhoneInput pl-4"
                placeholder="+491234..."
                value={stepData.phone}
                onChange={(value) => {
                    setStepData("phone", value)
                }}/>

        </IonItem>
    )
}
const StepDetails: React.FC<{ title?: string }> = ({children, title = ""}) => {
    const swiper = useSwiper()
    const setStepData = useUser(state => state.setStepData)
    const stepData = useUser(state => state.stepData)
    const canContinue = stepData.firstName && stepData.lastName && stepData.birthdate && stepData.phone
    return <SlideContent title={"Deine Details"} canContinue={canContinue}>
        <div>
            <form className="" onSubmit={() => {

            }}>
                <div className="text-sm">


                    <FormInput value={stepData.firstName} name={"firstName"} title={"Vorname"} onChange={setStepData}/>
                    <FormInput value={stepData.lastName} name={"lastName"} title={"Nachname"} onChange={setStepData}/>
                    <FormInput value={stepData.birthdate} name={"birthdate"} type={"date"} autocomplete={"bday-day"} title={"Geburtsdatum"} onChange={setStepData}/>

                    <FormPhoneInput/>
                    {/*<FormInput value={stepData.phone} name={"phone"} pattern="\d*" autocomplete={"tel"} title={"Telefon"} placeholder={"+491234.."} onChange={setStepData}/>*/}

                    <div className="pt-4">
                        <UploadItem
                            type={"Bewerbungsfoto"}
                            uploads={stepData.uploads}
                            onChange={(type, url) => {
                            }}/>
                    </div>
                </div>
                {/*<FormInput name={"avatar"} title={ "Profilbild" } onChange={ setStepData }/>*/}
            </form>
        </div>
    </SlideContent>
}


export default StepDetails