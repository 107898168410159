import React from 'react';
import { useSwiper } from 'swiper/react';
import { FormSwitchBucketButton, SlideContent } from '../Onboarding';
import useUser from '../../../store/useUser';
import {Link, useHistory} from "react-router-dom";
import {IonButton} from "@ionic/react";





const StepProfile: React.FC<{ title?: string }> = ({ children, title = "" })=>{
    const swiper = useSwiper()
    const history = useHistory()
    const setStepData = useUser(state=>state.setStepData)
    const stepData = useUser(state=>state.stepData)
    const isActive = !stepData.seekerTraits || stepData.seekerTraits.length < 3
    return <SlideContent title={ "Dein Profil" }
        onClick={ ()=>{
            {/*<div>{ stepData.degreeCurrent } Anzeigen</div>*/ }
            setStepData("hasFinishedWizzard", new Date().toISOString())
            history.push("/tabs/explore")
        }}
    >
        <div>
            <form className="" onSubmit={ ()=>{
            } }>

                <p>Wähle Deine 3 Top-Eigenschaften die Dir bei Dir und bei Arbeitskollegen am wichtigsten sind. Es gibt keine falschen Antworten.</p>

                <p className={"pt-8"}>Gewissenhaftigkeit</p>
                <FormSwitchBucketButton size={"small"} active={isActive} fieldName={ "seekerTraits" } value={ "Zuverlässig" }/>
                <FormSwitchBucketButton size={"small"} active={isActive} fieldName={ "seekerTraits" } value={ "Organisiert" }/>
                <FormSwitchBucketButton size={"small"} active={isActive} fieldName={ "seekerTraits" } value={ "Zielstrebig" }/>

                <p className={"pt-8"}>Extraversion</p>
                <FormSwitchBucketButton size={"small"} active={isActive} fieldName={ "seekerTraits" } value={ "Kontaktfreudig" }/>
                <FormSwitchBucketButton size={"small"} active={isActive} fieldName={ "seekerTraits" } value={ "Gesellig" }/>

                <p className={"pt-8"}>Teamfähigkeit</p>
                <FormSwitchBucketButton size={"small"} active={isActive} fieldName={ "seekerTraits" } value={ "Kooperativ" }/>
                <FormSwitchBucketButton size={"small"} active={isActive} fieldName={ "seekerTraits" } value={ "Freundlich" }/>
                <FormSwitchBucketButton size={"small"} active={isActive} fieldName={ "seekerTraits" } value={ "Mitfühlend" }/>

                <p className={"pt-8"}>Sicherheit</p>
                <FormSwitchBucketButton size={"small"} active={isActive} fieldName={ "seekerTraits" } value={ "Durchsetzungsstark" }/>
                <FormSwitchBucketButton size={"small"} active={isActive} fieldName={ "seekerTraits" } value={ "Entschieden" }/>
                <FormSwitchBucketButton size={"small"} active={isActive} fieldName={ "seekerTraits" } value={ "Selbstbewusst" }/>

            </form>
        </div>
    </SlideContent>
}


export default StepProfile