import useUser from '../../store/useUser';
import { IonIcon } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';
import React from 'react';
import { Link } from 'react-router-dom';

export function MatchStatusCard({
                                    to = "",
                                    onClick = ()=>{
                                    },
                                    className = ""
                                }) {
    const matches = useUser(state=>state.interestingMatches) || []

    if ( matches.length === 0 ) return <div className="text-left  border-dashed border-yellow-500 text-yellow-700  rounded-xl text-white w-full">
        <div className="px-4 py-8 text-center">
            <div className="text-xl font-bold">
                Noch keine Matches
            </div>
            <div>Finde Stellen. Swipe nach Rechts.</div>
        </div>
    </div>
    return <Link to={ to } className={ className }>
        <button
            onClick={ onClick }
            className="text-left bg-yellow-500  rounded-xl text-white w-full">
            <div className="px-4 py-8">

                <div className="text-4xl text-center">
                    Matches anzeigen
                </div>

            </div>
            <div className="bg-[rgba(0,0,0,0.05)] text-white px-4 py-2 text-xs text-center">
                { matches.length } <span className="font-light">Matches</span>
            </div>
            {/*<div>Du hast neue Nachrichten.</div>*/ }
        </button>
    </Link>
}