import React from 'react';
import {IonButton, IonDatetime, IonDatetimeButton, IonModal} from '@ionic/react';

const DatePicker:React.FC<any> = ({onChange, children, placeholder="",...props})=>(
    <>
        <IonDatetimeButton
            onChange={ (e:any)=>console.log(e)}
            datetime="date"/>
        <IonModal keepContentsMounted={ true }    >
            <IonDatetime
                onIonBlur={ (e:CustomEvent)=>console.log(e) }
                placeholder={placeholder}
                locale="de-DE"
                onIonChange={ onChange }
                presentation="month-year"
                id="date"
                {...props}
            />
        </IonModal>
    </>
);


export function DatePickerButton(props:any) {
    return (
        <>
            <IonDatetimeButton
                datetime="datetime">
                <IonButton slot={"time"}>&nbsp;</IonButton>
            </IonDatetimeButton>

            <IonModal keepContentsMounted={true}>
                <IonDatetime  presentation={"month-year"} id="datetime" {...props}></IonDatetime>
            </IonModal>
        </>
    );
}

export default DatePicker;