import React, { createContext, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
    IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonBadge,
} from '@ionic/react';
import { stop, chatbubbles, colorFilter } from 'ionicons/icons';
import './Tabs.scss';
import Explore from '../Explore/Explore';
import Highlights from '../Highlights/Highlights';
import Matches from '../Matches/Matches';
import Discover from '../Discover/Discover';
import useUser from '../../store/useUser';


export const TabContext = createContext({visible:true, setVisible: (v:boolean) => {}})

const Tabs: React.FC<{}> = ()=>{
    const [ visible, setVisible ] = useState(true)
    const matches = useUser(state=>state.matches)

    return (
        <TabContext.Provider value={ { visible, setVisible } }>
            <IonTabs className="tabs-top">
                <IonRouterOutlet>
                    <Redirect exact path="/tabs" to="/tabs/explore"/>
                    <Route path="/tabs/explore" component={ Explore } exact/>
                    <Route path="/tabs/discover" component={ Discover } exact/>
                    <Route path="/tabs/highlights" component={ Highlights } exact/>
                    <Route path="/tabs/matches" component={ Matches } exact/>
                </IonRouterOutlet>

                <IonTabBar slot="bottom" className="tabs-page tab-bar-no-border" style={{display: visible ? "flex": "none"}}>
                    <IonTabButton tab="explore" href="/tabs/explore">
                        Home
                        {/*<img className="tab-icon-inactive" src="assets/img/logo_small_bw.png" width="24" alt="" />*/ }
                        {/*<img className="tab-icon-active" src="assets/img/logo_small.png" width="24" alt="" />*/ }
                    </IonTabButton>
                    <IonTabButton tab="me" href="/tabs/discover">
                        Matches
                        {/*{matches.length && <IonBadge color="primary">{matches.length}</IonBadge>}*/ }
                    </IonTabButton>
                    {/*<IonTabButton tab="highlights" href="/tabs/highlights" className="color-gold">*/ }
                    {/*  <IonIcon icon={ stop } className="rotate-45" />*/ }
                    {/*  <IonBadge color="primary">9</IonBadge>*/ }
                    {/*</IonTabButton>*/ }
                    <IonTabButton tab="matches" href="/userprofile">
                        Dein Profil
                        {/*<IonIcon icon={ chatbubbles } />*/ }
                    </IonTabButton>

                </IonTabBar>
            </IonTabs>
        </TabContext.Provider>
    );
};

export default Tabs;
