import create from "zustand";
import api from './api';


interface IJobDomain {
    _id: string,
    _created: number,
    _modified: number,
    name: string,
    description: string | null,
}

interface IStaticData {
    jobDomains: IJobDomain[],

    loadStaticData: () => void

}

const useStaticData = create<IStaticData>((set, get) => ({
    jobDomains: [],
    loadStaticData: async () => {
        const res = await api<{ jobDomains: IJobDomain[] }>("/api/jobDomains")
        const jobDomains = res.jobDomains
        //console.log({jobDomains})
        set({
            jobDomains,
        })
    },


}));

export default useStaticData;
