import React from 'react';
import {useSwiper} from 'swiper/react';
import {FormInput, FormRadioItem, FormSwitchButton, SlideContent} from '../Onboarding';
import useUser from '../../../store/useUser';
import {IonButton, IonLabel, IonListHeader, IonRadioGroup, IonSelect, IonSelectOption} from '@ionic/react';


const StepCareer: React.FC<{ title?: string }> = ({children, title = ""}) => {
    const swiper = useSwiper()
    const setStepData = useUser(state => state.setStepData)
    const stepData = useUser(state => state.stepData)
    return <SlideContent title={"Deine Laufbahn"}>
        <div>
            <form className="" onSubmit={() => {

            }}>
                <div>
                    <IonLabel>Berufserfahrung</IonLabel>
                    <div>
                        <IonButton size="small" expand={"block"} onClick={() => setStepData("careerCurrentExperience", "ENTRY")}
                                   fill={stepData.careerCurrentExperience === "ENTRY" ? "solid" : `outline`}>Berufseinstieg</IonButton>
                        <IonButton size="small" expand={"block"} onClick={() => setStepData("careerCurrentExperience", "INTERMEDIATE")}
                                   fill={stepData.careerCurrentExperience === "INTERMEDIATE" ? "solid" : `outline`}>Erfahren</IonButton>
                        <IonButton size="small" expand={"block"} onClick={() => setStepData("careerCurrentExperience", "SENIOR")}
                                   fill={stepData.careerCurrentExperience === "SENIOR" ? "solid" : `outline`}>Senior</IonButton>
                    </div>

                    <div className="pt-5">

                        <IonLabel>Wie lange bist Du in Deiner Branche tätig?</IonLabel>
                        <div>
                            <FormSwitchButton size={"small"} fieldName={"brancheCurrentTime"} value="0"/>
                            <FormSwitchButton size={"small"} fieldName={"brancheCurrentTime"} value="1-3"/>
                            <FormSwitchButton size={"small"} fieldName={"brancheCurrentTime"} value="4-6"/>
                            <FormSwitchButton size={"small"} fieldName={"brancheCurrentTime"} value="7-10"/>
                            <FormSwitchButton size={"small"} fieldName={"brancheCurrentTime"} value="10-15"/>
                            <FormSwitchButton size={"small"} fieldName={"brancheCurrentTime"} value="15+"/>
                        </div>
                    </div>

                    <div className="pt-5">

                        <IonLabel>Dein Letzter Arbeitgeber</IonLabel>
                        <div>
                            <FormInput value={stepData.lastEmployerName} name={"lastEmployerName"} title={"Dein letzter Arbeitgeber"} onChange={setStepData}/>

                        </div>
                    </div>

                    <div className="pt-5">
                        <IonLabel>Wie viele Jahre warst Du dort?</IonLabel>
                        <div>
                            <FormSwitchButton size={"small"} fieldName={"lastEmployerTime"} value="0"/>
                            <FormSwitchButton size={"small"} fieldName={"lastEmployerTime"} value="1-3"/>
                            <FormSwitchButton size={"small"} fieldName={"lastEmployerTime"} value="4-6"/>
                            <FormSwitchButton size={"small"} fieldName={"lastEmployerTime"} value="7-10"/>
                            <FormSwitchButton size={"small"} fieldName={"lastEmployerTime"} value="10-15"/>
                            <FormSwitchButton size={"small"} fieldName={"lastEmployerTime"} value="15+"/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </SlideContent>
}


export default StepCareer