import create from "zustand";
import api, { setAuthToken } from './api';
import _ from "lodash"
import { IJobMatch } from '../pages/Discover/Discover';
import JobProfile, {EMatchState} from '../model/JobProfile';
import {INotification} from "components/NotificationsList";

let lastUpdate: any = {}
const debouncedUpdate = _.debounce(async function(jobSeekerId: string) {
    const promise = api(`/api/user/jobSeeker/${ jobSeekerId }`, {
        mode: "cors",
        data: lastUpdate,
        method: "POST"
    })
    lastUpdate = {}
    const res = await promise
    //console.log({ res })
    //console.log('Function debounced after 1000ms!');

}, 1000, {
    trailing: true,
});

export interface ISession {
    _id: string,
    email: string,
    hasProfile: boolean
    primarySeekerId: string
}

interface UserState {
    matches: IJobMatch[],
    canceledMatches : IJobMatch[],
    storedMatches : IJobMatch[],
    waitingMatches : IJobMatch[],
    interestingMatches : IJobMatch[],
    archivedMatches : IJobMatch[],
    newJobs : IJobMatch[],
    trashMatches : IJobMatch[],
    hotMatches : IJobMatch[],
    matchesTrashed: IJobMatch[],
    loadMatches: ()=>void;
    session: ISession | false,
    login: (userId: string, pass: string)=>void;
    logout: ()=>void;
    name: string;
    setName: (name: string)=>void;
    phoneNumber: string;
    setPhoneNumber: (phoneNumber: string)=>void;
    address: string;
    setAddress: (address: string)=>void;
    hobbies: string[];
    setHobbies: (hobbies: string[])=>void;
    stepData: any,
    setStepData: (fieldname: string, data: any)=>void,
    jobProfiles: JobProfile[],
    newJobProfiles: JobProfile[],
    setJobProfiles: (jobProfiles: JobProfile[])=>void,
    loadJobProfiles: ()=>Promise<JobProfile[]>,

    notifications:INotification[],
    setNotifications: (notifications:any) => void
    loadNotifications: ()=>void
    deleteNotification: (notificationId: string)=>void

}

export function useProfileStatus() {
    return useUser(state=>{
        let status = 0
        const stepData = state.stepData
        const {
            brancheCurrent,
            careerCurrentDegree,
            careerCurrentExperience,
            careerStartDate,
            jobType,
            seekTraits,
            seekerTraits,
            phone
        } = stepData
        const majors = [ brancheCurrent, jobType, seekTraits ].map(v=>{
            if ( v && v.length > 2 ) return 20
            return 0
        })

        const minors = [ careerCurrentDegree, careerCurrentExperience, careerStartDate, seekerTraits, phone ].map(v=>{
            if ( v && v.length > 2 ) return 10
            return 0
        })
        const majorPts = majors.reduce((acc: number, current: number)=>( acc + current ), 0)
        const minorPts = minors.reduce((acc: number, current: number)=>( acc + current ), 0)
        status = Math.min(100, majorPts + minorPts)
        return { status }
    })
}

const useUser = create<UserState>((set, get)=>( {
    notifications:[],

    setNotifications: (notifications:any) => {
        set({notifications})
    },
    loadNotifications: async()=>{
            const res = await api<{ notifications: INotification[] }>(`/api/user/notifications`, {
                mode: "cors",
                method: "GET"
            })

        set({notifications: res.notifications})
    },
    deleteNotification: async(notificationId: string)=>{
        const res = await api<{ notifications: INotification[] }>(`/api/user/notification/${notificationId}`, {
            mode: "cors",
            method: "DELETE"
        })
        const notifications = get().notifications
        set({notifications: notifications.filter(n => n._id !== notificationId)})
    },

    newJobs: [],
    matches: [],
    matchesTrashed: [],
    canceledMatches : [],
    storedMatches : [],
    waitingMatches : [],
    interestingMatches : [],
    hotMatches : [],
    trashMatches : [],
    archivedMatches : [],
    loadMatches: async()=>{
        const res = await api<{ matches: IJobMatch[] }>("/api/jobMatch")
        const all =  res.matches.map(match=>{
            match.jobProfile = new JobProfile(match.jobProfile)
            // TWO STATES FOR CANCELED
            if(match.matchStateCompany === EMatchState.REJECTED){
                match.matchStateCompany = EMatchState.CANCELED
            }
            return match
        })
        const matchesTrashed = all.filter(m => m.matchStateSeeker === "TRASHED")
        const matches = all
            .filter(m => m.matchStateSeeker !== "TRASHED")
            .filter(m => m.company)

        const storedMatches = matches.filter(m => m.matchStateSeeker === EMatchState.STORED)

        const interestingMatches = matches.filter(m => {
            if (m.matchStateSeeker === EMatchState.STORED) return false
            if (m.matchStateSeeker === EMatchState.TRASHED) return false
            if (m.matchStateSeeker === EMatchState.NONE) return false
            return true
        })

        const canceledMatches = matches.filter(m => {
            if (m.matchStateCompany === EMatchState.CANCELED) return true
            if (m.matchStateCompany === EMatchState.NONE) return true
            return false
        })

        const waitingMatches = interestingMatches.filter(m => {
            if (m.matchStateSeeker === EMatchState.STORED) return false
            if (m.matchStateCompany === null) return true
            if (m.matchStateCompany === EMatchState.CONTACTED) return false
            if (m.matchStateCompany === EMatchState.REQUESTED) return false
            if (m.matchStateCompany === EMatchState.CANCELED) return false
            return true
        })

        const hotMatches = interestingMatches.filter(m => {
            if (m.matchStateSeeker === EMatchState.STORED) return false
            if (m.matchStateCompany === null) return false
            if (m.matchStateCompany === EMatchState.CONTACTED) return true
            if (m.matchStateCompany === EMatchState.REQUESTED) return true
            if (m.matchStateCompany === EMatchState.CANCELED) return false
            return true
        })


        const trashMatches = matches.filter(m => m.matchStateSeeker !== EMatchState.TRASHED)
        const archivedMatches = matches.filter(m => m.matchStateSeeker !== EMatchState.CANCELED)

        set({
            storedMatches,
            trashMatches,
            archivedMatches,
            interestingMatches,
            canceledMatches,
            waitingMatches,
            hotMatches,
            matches,
            matchesTrashed
        })
    },

    session: false,
    login: async(userId, pass)=>{
        let res = await api<{ token: string, session: ISession }>("/api/user/login", {
            method: "post",
            data: { userId: userId, pass }
        })
        //console.log({ res })
        const token = res.token

        if ( token ) {
            setAuthToken(res.token)
        }

        const r = await api<{ jobSeeker: any }>(`/api/user/jobSeeker/${ res.session.primarySeekerId }`, {
            method: "GET"
        })
        const stepData = useUser.getState().stepData
        set({ stepData: { ...stepData, ...r.jobSeeker } })
        set({ session: res.session })
        useUser.getState().loadMatches()
    },

    logout: ()=>{
        set({
            session: false
        })
        document.location.reload()
    },

    stepData: {
        careerStartDate: "Egal"
    },

    setStepData: async(name: string, value: any)=>{
        set((state)=>{
            //console.log("SET STEP DATA", {name, value})
            const jobSeekerId = state.session && state.session.primarySeekerId

            if ( !jobSeekerId ) return state

            // lastUpdate = {...lastUpdate, { [ name ]: value }}
            lastUpdate[ name ] = value
            debouncedUpdate(jobSeekerId)
            return ( {
                ...state,
                stepData: { ...state.stepData, [ name ]: value }
            } )
        })
    },
    jobProfiles: [],
    newJobProfiles: [],
    setJobProfiles: (jobProfiles)=>{
        console.log("SET", { jobProfiles })
        const newJobProfiles = jobProfiles.filter(job => {
            if (!job.match) return true
            const isNone = job.match?.matchStateSeeker === "NONE"
            const isOld = job.match._created < new Date().getTime() - 1000 * 60 * 60 * 24 * 7
            return isNone && !isOld
        })
        set({ jobProfiles, newJobProfiles })
    },
    loadJobProfiles: async()=>{
        let result = await api<{ jobProfiles: Array<JobProfile> }>("/api/user/jobProfiles")
            .then(res=>res.jobProfiles.map((item: JobProfile)=>{
                return new JobProfile(item)
            }))
        useUser.getState().setJobProfiles(result)
        //console.log({ result })
        return result
    },

    name: "",
    setName: (name)=>
        set((state)=>( {
            ...state,
            name
        } )),
    phoneNumber: "",
    setPhoneNumber: (phoneNumber)=>
        set((state)=>( {
            ...state,
            phoneNumber
        } )),
    address: "",
    setAddress: (address)=>
        set((state)=>( {
            ...state,
            address
        } )),
    hobbies: [],
    setHobbies: (hobbies)=>
        set((state)=>( {
            ...state,
            hobbies
        } ))
} ));

// DEBUG :)
// @ts-ignore
global.useUser = useUser

export default useUser;
