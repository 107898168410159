import create from "zustand";
import api from './api';


interface JobSeekerState {
    firstName: string;
    lastName: string;
    additionalName: string;
    branch: string;
    street: string;
    postcode: string;
    city: string;
    country: string;
    _mby: string;
    _by: string;
    _modified: number;
    _created: number;
    _id: string;
    streetNumber: string;
    email: string;
    setJobSeeker: (data: Partial<JobSeekerState>)=>void
    fetchJobSeeker: (jobSeekerId: string)=>void
}

const useJobSeeker = create<JobSeekerState>((set)=>( {
    firstName: "",
    lastName: "",
    additionalName: "",
    branch: "",
    street: "",
    postcode: "",
    city: "",
    country: "",
    _mby: "",
    _by: "",
    _modified: 0,
    _created: 0,
    _id: "",
    streetNumber: "",
    email: "",
    fetchJobSeeker: async(jobSeekerId)=>{
        const seeker = await api<Partial<JobSeekerState>>(`/api/user/jobSeeker/${ jobSeekerId }`)
        useJobSeeker.setState(seeker)
        //console.log({ seeker })
    },

    setJobSeeker: (data)=>{
        set(data)
    }
} ));

export default useJobSeeker;
