import {Redirect, Route, useHistory} from 'react-router-dom';
import {IonApp, IonRouterOutlet, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Landing from './pages/Landing/Landing';
import Tabs from './pages/Tabs/Tabs';
import Chat from './pages/Chat/Chat';

/* Theme variables */
import './theme/variables.css';

// custom CSS
import './sass/variables.override.scss';
import './sass/helper.scss';
import './sass/app.scss';
import './sass/dark.scss';
import UserProfile from './pages/UserProfile/UserProfile';
import Onboarding from './pages/Onboarding/Onboarding';
import useUser from './store/useUser';
import React, {createContext, useContext, useEffect, useState} from 'react';
import io, {Socket} from 'socket.io-client'
import {Toaster} from 'react-hot-toast';
import notifications from "components/Notifications/Notifications"
import useStaticData from "store/useStaticData";


//console.log({notifications}, "ready")
setupIonicReact();


let socket: any
export const SocketContext = createContext<Socket | false>(false)

const SocketHandler: React.FC = ({children}) => {
    const session = useUser(state => state.session)
    const [s, setSocket] = useState<Socket | false>(false)
    const flag = 0
    useEffect(() => {

        async function connect() {


            //console.log("SOCKET:IO:CONNECTION")
            await fetch(`${process.env.REACT_APP_API}/api/socket`)
            socket = io(`${process.env.REACT_APP_API}`)
            socket.on('connect', () => {
                console.log('connected:socket')
            })

            socket.on("update", () => {
                //console.log("UPDATE")
                useUser.getState().loadMatches()
            })

            socket.on("notification", (message: string) => {
                //console.log("NOTIFICATION", {message})
                notifications.toast.success(message, {
                    icon: '👏',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                })
            })

            socket.on("connect_error", () => {
                setTimeout(() => {
                    // @ts-ignore
                    socket.connect();
                    session && socket.emit("account", session)
                }, 1000);
            });

            socket.on("disconnect", (reason: string) => {
                if (reason === "io server disconnect") {
                    // the disconnection was initiated by the server, you need to reconnect manually
                    socket.connect();
                    session && socket.emit("account", session)
                }
                // else the socket will automatically try to reconnect
            });

            socket.emit("subscribe", "Hello World")
            //console.log({socket})
            setSocket(socket)

            // @ts-ignore
            window["socket"] = socket
        }

        connect()
    }, [flag])


    return <SocketContext.Provider value={s}>
        {children}
    </SocketContext.Provider>
}

const AuthGuard: React.FC = () => {
    const history = useHistory()
    const session = useUser(state => state.session)
    const socket = useContext(SocketContext)

    //console.log({session, history})
    useEffect(() => {
        if (!session && history.location.pathname !== "/landing") {
            history.push("/landing")
        }
        if (session && socket) {
            socket.emit("account", session)
        }
    }, [session, socket])

    return null
}

const App: React.FC = () => {

    const once = true
    const loadNotifications = useUser(state => state.loadNotifications)
    const loadStaticData = useStaticData(state => state.loadStaticData)

    function refreshNotifications() {
        setTimeout(() => {
            loadNotifications()
            refreshNotifications()
        }, 5000 + Math.random() * 5000)
    }

    useEffect(() => {
        refreshNotifications()
        loadStaticData()
    }, [once])

    return (
        <IonApp>
            <Toaster
            position={"bottom-center"}
            />
            <SocketHandler>
                <IonReactRouter>
                    <AuthGuard/>
                    <IonRouterOutlet>
                        <Route path="/tabs" component={Tabs}/>
                        <Route path="/onboarding/:jobSeekerId" component={Onboarding} exact/>
                        <Route path="/chat" component={Chat} exact/>
                        <Route path="/userprofile" component={UserProfile}/>
                        <Route path="/landing" component={Landing} exact/>
                        <Route path="/" component={Landing} exact/>
                    </IonRouterOutlet>
                </IonReactRouter>
            </SocketHandler>
        </IonApp>
    );
}

export default App;
