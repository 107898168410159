let token = ""

export const setAuthToken = (newToken: string)=>{
    token = newToken
}


function api<T>(url: string, options: any = {}, noJSONHeader = false): Promise<T> {
    const customHeaders = options.headers
    const data = options.data
    const headers = {
        "content-type": "application/json",
        token,
        ...customHeaders,
    }
    if ( noJSONHeader ) delete headers[ "content-type" ]
    return fetch(`${ process.env.REACT_APP_API }${ url }`,
        {
            mode: "cors",
            body: data ? JSON.stringify(data) : null,
            ...options,
            headers
        })
        .then(response=>{
            if ( response.status === 401 ) {
                throw new Error(response.statusText)
            }
            if ( !response.ok ) {
                throw new Error(response.statusText)
            }
            return response.json() as Promise<T>
        })
}

export default api