import React, {useState} from "react";
import {IonButton, IonCol, IonRow} from "@ionic/react";
import "./SwipeCard.scss";
import ProfileImageSlides from "../ProfileImageSlides/ProfileImageSlides";
import JobProfile from '../../model/JobProfile';
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';

type Props = {
    job?: any;
    isPreview?: boolean;
    onNoMoreSlide?: (l: boolean) => void;
    onViewInfo?: (job: JobProfile) => void;
};

const SwipeJobCard: React.FC<Props> = ({
                                           job,
                                           isPreview,
                                           onNoMoreSlide,
                                           onViewInfo,
                                       }) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const handleNoMoreSlide = (isOnTheLeft: boolean) => {
        if (onNoMoreSlide) onNoMoreSlide(isOnTheLeft);
    };

    const handleViewInfo = () => {
        if (onViewInfo) onViewInfo(job);
    };

    const handleSlideChange = (index: number) => {
        setCurrentIndex(index);
    };

    return (
        <div className="swipe-card relative">
            <ProfileImageSlides
                images={job.images}
                isClickable
                onNoMoreSlide={handleNoMoreSlide}
                onChange={handleSlideChange}
            />

            <div className="absolute top-0 text-white z-10 w-full ">
                <div className="p-4 flex flex-row justify-between  ">
                    <div className="w-[60px] ">
                        <CircularProgressbarWithChildren
                            value={Number(job.matchingScore.score)}
                            circleRatio={0.75}
                            strokeWidth={5}
                            styles={buildStyles({
                                rotation: 1 / 2 + 1 / 8,
                                strokeLinecap: "round",
                                trailColor: "#686d76",
                                pathColor: job.matchingScore.color,
                                textColor: "#042C5C",
                                textSize: "5px",
                            })}
                        >
                            <div className="text-3xl font-bold">{job.matchingScore.score}</div>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className="w-full text-right">
                        <div className="text-xl font-bold">{job.company?.display}</div>
                        <div className="">{job.jobLocation}</div>
                    </div>

                </div>

            </div>

            <div className={"absolute z-[101] top-0 bottom-0 text-white left-0 right-0"}>

                <div className="absolute bottom-[220px] left-0 right-0 text-center px-8 ">
                    <div className="text-sm">{job.jobType.join(", ")}</div>
                    <div className="text-2xl font-bold pb-2 ">{job.jobTitle}</div>
                    <div>
                        <div>{job.jobSector?.name} {job.jobDepartment ? `/ ${job.jobDepartment}` : null}</div>
                    </div>
                    {currentIndex === 0 && (
                        <div className="pt-2 flex flex-row justify-around text-center ">
                            <IonButton size={"small"} onClick={handleViewInfo} color={"white"} fill={"outline"}>mehr</IonButton>
                        </div>
                    )}
                </div>
                {/*<div className="w-[40%] mx-auto mt-[35%]">*/}
                {/*    <CircularProgressbarWithChildren*/}
                {/*        value={ Number(job.matchingScore.score) }*/}
                {/*        circleRatio={ 0.75 }*/}
                {/*        strokeWidth={ 5 }*/}
                {/*        styles={ buildStyles({*/}
                {/*            rotation: 1 / 2 + 1 / 8,*/}
                {/*            strokeLinecap: "round",*/}
                {/*            trailColor: "#686d76",*/}
                {/*            pathColor: job.matchingScore.color,*/}
                {/*            textColor: "#042C5C",*/}
                {/*            textSize: "5px",*/}
                {/*        }) }*/}
                {/*    >*/}
                {/*        <div className="text-4xl font-bold">{ job.matchingScore.score }</div>*/}
                {/*    </CircularProgressbarWithChildren>*/}
                {/*</div>*/}
                {/*<div className="text-xl font-bold uppercase text-center">Matchingscore</div>*/}

            </div>

            <div className="card-caption">
                <div className="pt-4 pb-4 px-4">
                    <div className="flex flex-row ">
                        {/*<span className="text-2xl">{ job.jobTitle }</span>*/}
                        {/*<div className={ "ml-auto text-xl" }>{ job.jobIncome } €</div>*/}
                    </div>
                </div>

            </div>
        </div>
    );
};

SwipeJobCard.defaultProps = {
    job: {},
    isPreview: false,
    onNoMoreSlide: () => {
    },
    onViewInfo: () => {
    },
};

export default SwipeJobCard;
