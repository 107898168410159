import React, { useEffect, useState } from 'react';
import {
    IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonText, IonList, IonListHeader, IonLabel, IonIcon
} from '@ionic/react';
import {
    closeCircleOutline

} from 'ionicons/icons';
import './Passions.scss';
import api from '../../store/api';
import { FormSwitchBucketButton } from '../Onboarding/Onboarding';
import useUser from '../../store/useUser';


const jobBenefits = [
    {
        "domain": null,
        "benefit": "Betriebliche Altersvorsorge",
        "searchKeys": "Betriebliche Altersvorsorge",
        "_id": "790d839d64303219cf00008e"
    },
    {
        "domain": null,
        "benefit": "Dienstwagen",
        "searchKeys": "Dienstwagen",
        "_id": "790d83a33461325c7c0002b8"
    },
    {
        "domain": null,
        "benefit": "Überstunden bezahlt",
        "searchKeys": "überstunden",
        "_id": "790d83a5383233a07a0001d6"
    },
    {
        "domain": null,
        "benefit": "Zuschläge für Feiertage",
        "searchKeys": "Zuschläge, Feiertage",
        "_id": "790d83a86439383e6700024a"
    },
    {
        "domain": null,
        "benefit": "überdurchschnittliches Gehalt",
        "searchKeys": "überdurchschnittlich,Gehalt",
        "_id": "790d83ab3238384710000172"
    },
    {
        "domain": null,
        "benefit": "Flexibler Dienstplan",
        "searchKeys": "Flexibel,Dienstplan",
        "_id": "790d83ad32646465f50000e1"
    },
    {
        "domain": null,
        "benefit": "führen eines Arbeitszeitkontos",
        "searchKeys": "Arbeitszeitkontos",
        "_id": "790d83b3626337df0800029b"
    },
    {
        "domain": null,
        "benefit": "Fort und Weiterbildungsmöglichkeiten",
        "searchKeys": "Weiterbildung, Fortbildung",
        "_id": "790d83b5383338f25e000303"
    },
    {
        "domain": null,
        "benefit": "Mitarbeiter-Einkaufsvorteile",
        "searchKeys": "Einkaufsvorteile,Vorteile",
        "_id": "790d83b839323045ee00017b"
    },
    {
        "domain": null,
        "benefit": "Prämiensystem bei Anwerbung",
        "searchKeys": "Prämiensystem,Anwerbung,Mitarbeiterwerbenmitarbeiter,Werben",
        "_id": "790d83bb613162f5480001e2"
    },
    {
        "domain": null,
        "benefit": "Gute Verkehrsanbindung",
        "searchKeys": "Verkehrsanbindung",
        "_id": "790d83be3866645c4b000266"
    },
    {
        "domain": null,
        "benefit": "Betriebseigene Kinderbetreuung",
        "searchKeys": "Kinderbetreuung",
        "_id": "790d83c06462364cf50000fe"
    },
    {
        "domain": null,
        "benefit": "Gesundheitsangebote",
        "searchKeys": "Gesundheitsangebote",
        "_id": "790d83c3613661bf9a0000b6"
    },
    {
        "domain": null,
        "benefit": "Freizeitangebote",
        "searchKeys": "Freizeitangebote",
        "_id": "790d83c73434642772000047"
    },
    {
        "domain": null,
        "benefit": "Personalrestaurants",
        "searchKeys": "Personalrestaurants",
        "_id": "790d83cb626663010700037c"
    },
    {
        "domain": null,
        "benefit": "corporate benefits",
        "searchKeys": "corporate benefits,benefits",
        "_id": "790d83ce666566babb00031a"
    },
    {
        "domain": null,
        "benefit": "ÖPNV-Monatskarten",
        "searchKeys": "ÖPNV-Monatskarten,Busfahrkarte,Monatskarte,Zugkarte,Zugticket",
        "_id": "790d83d135333706bb000126"
    },
    {
        "domain": null,
        "benefit": "Kinderzuschlag",
        "searchKeys": "Kinderzuschlag",
        "_id": "790d83d4393339b2390001f1"
    },
    {
        "domain": null,
        "benefit": "Einarbeitungskonzept",
        "searchKeys": "Einarbeitungskonzept",
        "_id": "790d83d66337312d67000382"
    },
    {
        "domain": null,
        "benefit": "Bike-Leasing",
        "searchKeys": "Bike-Leasing,Jobrad",
        "_id": "790d83d86136641ed2000355"
    },
    {
        "domain": null,
        "benefit": "Jahressonderzahlung",
        "searchKeys": "Jahressonderzahlung",
        "_id": "790d86a83266615f090000e1"
    },
    {
        "domain": null,
        "benefit": "Mitarbeiterevents",
        "searchKeys": "Mitarbeiterevents",
        "_id": "790d86ac323266277a00037f"
    },
    {
        "domain": null,
        "benefit": "Unbefristeter Arbeitsvertrag",
        "searchKeys": "Unbefristeter Arbeitsvertrag, Unbefristet",
        "_id": "790d86ae6432341f27000156"
    },
    {
        "domain": null,
        "benefit": "Wechselprämie",
        "searchKeys": "Wechselprämie",
        "_id": "790d86b0653761ba3a00027b"
    },
    {
        "domain": null,
        "benefit": "Vermögenswirksame Leistungen",
        "searchKeys": "Vermögenswirksame Leistungen,VL",
        "_id": "790d86b13339314b7c000038"
    },
    {
        "domain": null,
        "benefit": "Fitnessstudio",
        "searchKeys": "Fitnessstudio,Sport,Sportangebot,Mitgliedskarte im Fittnesstudio",
        "_id": "790d86b3356234ab780002b3"
    },
    {
        "domain": null,
        "benefit": "Springerzuschläge",
        "searchKeys": "Springerzuschläge",
        "_id": "790d86b4313837dbc4000096"
    }
]

type Props = {
    onClose: ()=>void,
}

const Passions: React.FC<Props> = ({ onClose })=>{

    const [ benefits, setBenefits ] = useState<any>([])
    const seekTraits = useUser(state=>state.stepData.seekTraits)
    const flag = 2131
    useEffect(()=>{
        const load = async()=>{
            // const ben = await api("/api/jobBenefits")
            //console.log(jobBenefits)
            setBenefits(jobBenefits)
        }
        load()
    }, [ flag ])

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Deine Wünsche </IonTitle>

                    <IonButtons slot="end">
                        <IonButton onClick={ onClose }>
                            <IonIcon icon={ closeCircleOutline }/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent className="passions-page bg-light">
                <div className="ion-padding">
                    <IonText color="medium">Deine Wünsche für Deinen nächsten Einsatz. Wähle mindestens 5. </IonText>
                </div>

                <IonList className="list-custom passion-list">
                    <IonListHeader>
                        <IonLabel>Wünsche</IonLabel>
                        <IonText color="medium" className="tag-count">({ seekTraits?.length }/5)</IonText>
                    </IonListHeader>

                    <div className="ion-padding">
                        { benefits.map((item: any)=>{
                            return <FormSwitchBucketButton
                                size={"small" }
                                key={ item._id }
                                removeOnly={seekTraits?.length >= 5}
                                fieldName={ "seekTraits" }
                                value={ item.benefit }/>
                        }) }

                    </div>
                </IonList>
            </IonContent>
        </>
    );
};

Passions.defaultProps = {}

export default Passions;
