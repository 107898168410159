import React, {ChangeEvent, FormEvent, useRef, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonRadioGroup,
    IonSelect, IonSelectOption, IonSpinner,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import {closeOutline, downloadOutline} from 'ionicons/icons';
import Passions from '../Passions/Passions';
import './ProfileEdit.scss';
import useUser, {ISession} from '../../store/useUser';
import DatePicker, {DatePickerButton} from '../../components/DatePicker';
import {FormInput, FormSwitchBucketButton, FormSwitchButton} from '../Onboarding/Onboarding';
import api from '../../store/api';
import notifications from '../../components/Notifications/Notifications';
import useStaticData from "store/useStaticData";
import {StepTargetJobSectors} from "pages/Onboarding/step/StepPersonal";
import {FormPhoneInput} from "pages/Onboarding/step/StepDetails";

type Props = {
    user: any,
    onClose: () => void,
}


export const UploadItem: React.FC<{ type?: string, uploads: any, onChange: (type: string, url: string) => void }> =
    ({uploads = [], type = "Weitere Anlagen", onChange}) => {
        const documents = uploads?.documents || []
        const document = uploads?.documents?.find((doc: any) => doc.type === type && doc.url)
        const [showEdit, setShowEdit] = useState(!document)
        const setStepData = useUser(state => state.setStepData)
        const [loading, setLoading] = useState(false)

        const handleChange = (type: string, url: string) => {
            if (!url) {
                const newDocs = documents.filter((doc: any) => doc.type !== type)
                setStepData("uploads", {documents: newDocs})
            } else {
                setStepData("uploads", {documents: [...documents, {url, type}]})
            }
            onChange && onChange(type, url)
        }

        async function onFileChange(fileChangeEvent: ChangeEvent<HTMLInputElement>) {
            // Get a reference to the file that has just been added to the input
            const file = fileChangeEvent.target.files?.[0];
            //console.log({file})
            if (!file) return
            let formData = new FormData();
            // Add the file that was just added to the form data
            formData.append("file", file, file.name);
            formData.append("type", type);
            //console.log("UPLOADING", {file, formData})
            setLoading(true)
            const response = await api<{ url: string }>(`/api/user/upload`, {
                method: 'POST',
                body: formData,
                mode: "cors"
            }, true);

            response.url && handleChange(type, response.url)
            notifications.show("Datei hinterlegt")
            setLoading(false)
            //console.log({response})
        }

        return <div className="UploadItem w-full relative pr-1">
            {/*<pre className="text-xs">{JSON.stringify({document, uploads}, null, 2)}</pre>*/}
            {document && <div className="grid grid-cols-4 gap-4">

				<div className=" col-span-2">
					<a
						className=""
						href={process.env.REACT_APP_API + "/api" + document.url} download target={"_blank"}>
						<IonButton className="w-full">{type} <IonIcon icon={downloadOutline}/></IonButton>
					</a>
				</div>


				<div className="col-span-2">
					<IonButton
                        className={"w-full"}
                        fill={"outline"} onClick={() => {
                        handleChange(type, "")
                    }}>Entfernen</IonButton>
				</div>
			</div>}

            {(!document) && <div className="flex  w-full py-2 items-center w-full  left-0 right-0 top-0 bottom-0 bg-white  flex-row">
				<label htmlFor={`cv-uploader-${type}`} className="flex flex-col items-center pointer w-full px-2">
					<div className="border-2 border-dashed p-2 mr-4  text-center text-xs text-gray-500 w-full rounded-lg relative min-h-[30px]">
                        {loading && <div className="absolute top-0 bottom-0 left-0 right-0"><IonSpinner name="dots"></IonSpinner></div>}
                        {!loading && <div>
							<span className="font-bold">{type}</span> {" "}
							<span>hochladen</span>
						</div>}

					</div>
				</label>
				<div className="hidden">
					<input id={`cv-uploader-${type}`} type="file" onChange={(ev) => onFileChange(ev)}/>
				</div>

			</div>}
        </div>
    }


const ProfileEdit: React.FC<Props> = ({user, onClose}) => {
    const [segmentView, setSegmentView] = useState<string>('EDIT');
    const [isPassionsOpen, setIsPassionsOpen] = useState<boolean>(false);
    const [aboutMe, setAboutMe] = useState<string>(`Working for satisfying IcONIC Dreams`);
    const stackRef = useRef<HTMLDivElement>(null);
    const jobDomains = useStaticData(state => state.jobDomains)
    const session = useUser(state => state.session)
    const setStepData = useUser(state => state.setStepData)
    const stepData = useUser(state => state.stepData)
    const handleNoMoreSlide = (isOnTheLeft: boolean = true) => {
        if (stackRef && stackRef.current) {
            const className = isOnTheLeft ? 'rotate-left' : 'rotate-right';

            // @ts-ignore
            stackRef.current.classList.add(className);
            setTimeout(() => {
                // @ts-ignore
                stackRef.current.classList.remove(className);
            }, 250);
        }
    }

    const handleOpenPassions = () => {
        setIsPassionsOpen(true);
    }

    const isAcademic = stepData.careerCurrentDegree === "Bachelor" || stepData.careerCurrentDegree === "Master"
    const isMaster = stepData.careerCurrentDegree === "Meister"
    const isApprentice = stepData.careerCurrentDegree === "Berufsausbildung" || stepData.careerCurrentDegree === "Ausbildung" || stepData.careerCurrentDegree === "Sonstiges"
    const isActiveTraitSelection = !stepData.seekerTraits || stepData.seekerTraits.length < 3
    const documents = stepData.uploads?.documents || []

    if (!session) return null
    //console.log({documents})
    return (
        <>

            <IonHeader className="header-custom">
                <IonToolbar>
                    <IonTitle>Bearbeiten</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="primary" onClick={() => {

                            onClose()

                        }}>
                            <IonIcon icon={closeOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                {/*<IonToolbar className="toolbar-no-border">*/}
                {/*    <IonSegment*/}
                {/*        className="segment-custom"*/}
                {/*        value={ segmentView }*/}
                {/*        onIonChange={ e=>setSegmentView(e.detail.value as string) }*/}
                {/*        mode="md"*/}
                {/*    >*/}
                {/*        <IonSegmentButton value="EDIT">*/}
                {/*            <IonLabel>*/}
                {/*                Bearbeiten*/}
                {/*            </IonLabel>*/}
                {/*        </IonSegmentButton>*/}
                {/*        <IonSegmentButton value="PREVIEW">*/}
                {/*            <IonLabel>*/}
                {/*                Ansicht*/}
                {/*            </IonLabel>*/}
                {/*        </IonSegmentButton>*/}
                {/*    </IonSegment>*/}
                {/*</IonToolbar>*/}
            </IonHeader>

            <IonContent className="profile-edit-page bg-light">
                <div className="full-height">
                    {/*<pre>{JSON.stringify(stepData.uploads, null, 2)}</pre>*/}

                    {
                        segmentView === 'EDIT' &&
						<div className="segment-view">
                            {/*<div className="photos-edit">*/}
                            {/*  <IonGrid>*/}
                            {/*    <IonRow>*/}
                            {/*      <IonCol size="4" className="photo-item">*/}
                            {/*        <div className="photo-image background-img" style={{ backgroundImage: 'url(assets/img/profile/emma2.jpeg)' }} />*/}
                            {/*        <div className="photo-button">*/}
                            {/*          <IonIcon icon={close} />*/}
                            {/*        </div>*/}
                            {/*      </IonCol>*/}
                            {/*      <IonCol size="4" className="photo-item">*/}
                            {/*      <div className="photo-image background-img" style={{ backgroundImage: 'url(assets/img/profile/emma3.jpeg)' }} />*/}
                            {/*        <div className="photo-button">*/}
                            {/*          <IonIcon icon={close} />*/}
                            {/*        </div>*/}
                            {/*      </IonCol>*/}
                            {/*      <IonCol size="4" className="photo-item">*/}
                            {/*      <div className="photo-image background-img" style={{ backgroundImage: 'url(assets/img/profile/emma4.jpeg)' }} />*/}
                            {/*        <div className="photo-button">*/}
                            {/*          <IonIcon icon={close} />*/}
                            {/*        </div>*/}
                            {/*      </IonCol>*/}
                            {/*      {*/}
                            {/*        [0,1,2,3,4,5].map(i => (*/}
                            {/*          <IonCol size="4" className="photo-item no-photo" key={ i }>*/}
                            {/*            <div className="photo-image background-img" />*/}
                            {/*            <div className="photo-button photo-button-invert">*/}
                            {/*              <IonIcon icon={add} />*/}
                            {/*            </div>*/}
                            {/*          </IonCol>*/}
                            {/*        ))*/}
                            {/*      }*/}
                            {/*    </IonRow>*/}
                            {/*  </IonGrid>*/}
                            {/*</div>*/}

							<IonList className="list-custom">
								<IonListHeader>
									<IonLabel>Was suchst Du?</IonLabel>
								</IonListHeader>
								<IonItem>
									<div className="flex flex-col w-full py-2">
										<StepTargetJobSectors/>
									</div>
								</IonItem>
							</IonList>

                            {/*DEIN TRAUMJOB*/}
							<IonList className="list-custom">
								<IonListHeader>
									<IonLabel>Deine Branche</IonLabel>
								</IonListHeader>
								<IonItem>
									<div className="w-full grid grid-cols-1 py-2">
                                        {jobDomains.map(jobDomain => {
                                            return <FormSwitchButton key={jobDomain.name} fieldName={"brancheCurrent"} value={jobDomain.name}/>
                                        })}
									</div>

								</IonItem>
							</IonList>

							<IonList className="list-custom">
								<IonListHeader>
									<IonLabel>Deine Anlagen</IonLabel>
								</IonListHeader>

								<IonItem>
									<UploadItem
										type={"Lebenslauf"}
										uploads={stepData.uploads}
										onChange={(type, url) => {
                                        }}/>
								</IonItem>

								<IonItem>
									<UploadItem
										type={"Zeugnis"}
										uploads={stepData.uploads}
										onChange={(type, url) => {

                                        }}/>
								</IonItem>


								<IonItem>
									<UploadItem
										type={"Zertifikate (A)"}
										uploads={stepData.uploads}
										onChange={(type, url) => {
                                        }}/>
								</IonItem>

								<IonItem>
									<UploadItem
										type={"Zertifikate (B)"}
										uploads={stepData.uploads}
										onChange={(type, url) => {
                                        }}/>
								</IonItem>


								<IonItem>
									<UploadItem
										type={"Weitere Datei hochladen"}
										uploads={stepData.uploads}
										onChange={(type, url) => {
                                        }}/>
								</IonItem>

                                {/*<IonItem>*/}
                                {/*	<UploadItem*/}
                                {/*		type={"Zeugnis"}*/}
                                {/*		uploads={stepData.uploads}*/}
                                {/*		onChange={url => {*/}
                                {/*            setStepData("uploads", {documents: [...documents, {url, type: "Zeugnis"}]})*/}
                                {/*        }}/>*/}
                                {/*</IonItem>*/}

                                {/*<IonItem>*/}
                                {/*	<UploadItem*/}
                                {/*		type={"Arbeitszeugnis"}*/}
                                {/*		uploads={stepData.uploads}*/}
                                {/*		onChange={url => {*/}
                                {/*            setStepData("uploads", {documents: [...documents, {url, type: "Arbeitszeugnis"}]})*/}
                                {/*        }}/>*/}
                                {/*</IonItem>*/}


                                {/*<IonItem>*/}
                                {/*	<UploadItem*/}
                                {/*		uploads={stepData.uploads}*/}
                                {/*		onChange={url => {*/}
                                {/*            setStepData("uploads", {documents: [...documents, {url, type: "Arbeitszeugnis2"}]})*/}
                                {/*        }}/>*/}
                                {/*</IonItem>*/}


							</IonList>

							<IonList className={"list-custom"}>
								<IonListHeader>
									<IonLabel>Deine Eigenschaften</IonLabel>
								</IonListHeader>

								<IonItem>
									<div className="flex flex-col w-full py-2">

										<p className={"pt-8"}>Gewissenhaftigkeit</p>
										<FormSwitchBucketButton size={"small"} isActive={isActiveTraitSelection} fieldName={"seekerTraits"} value={"Zuverlässig"}/>
										<FormSwitchBucketButton size={"small"} isActive={isActiveTraitSelection} fieldName={"seekerTraits"} value={"Organisiert"}/>
										<FormSwitchBucketButton size={"small"} isActive={isActiveTraitSelection} fieldName={"seekerTraits"} value={"Zielstrebig"}/>

										<p className={"pt-8"}>Extraversion</p>
										<FormSwitchBucketButton size={"small"} isActive={isActiveTraitSelection} fieldName={"seekerTraits"} value={"Kontaktfreudig"}/>
										<FormSwitchBucketButton size={"small"} isActive={isActiveTraitSelection} fieldName={"seekerTraits"} value={"Gesellig"}/>

										<p className={"pt-8"}>Teamfähigkeit</p>
										<FormSwitchBucketButton size={"small"} isActive={isActiveTraitSelection} fieldName={"seekerTraits"} value={"Kooperativ"}/>
										<FormSwitchBucketButton size={"small"} isActive={isActiveTraitSelection} fieldName={"seekerTraits"} value={"Freundlich"}/>
										<FormSwitchBucketButton size={"small"} isActive={isActiveTraitSelection} fieldName={"seekerTraits"} value={"Mitfühlend"}/>

										<p className={"pt-8"}>Sicherheit</p>
										<FormSwitchBucketButton size={"small"} isActive={isActiveTraitSelection} fieldName={"seekerTraits"} value={"Durchsetzungsstark"}/>
										<FormSwitchBucketButton size={"small"} isActive={isActiveTraitSelection} fieldName={"seekerTraits"} value={"Entschieden"}/>
										<FormSwitchBucketButton size={"small"} isActive={isActiveTraitSelection} fieldName={"seekerTraits"} value={"Selbstbewusst"}/>
									</div>
								</IonItem>

							</IonList>


							<IonList className="list-custom">
								<IonListHeader>
									<IonLabel>Zu wann suchst Du etwas?</IonLabel>
								</IonListHeader>
								<IonItem>
									<div className="flex flex-col w-full py-2">
										<IonButton onClick={() => setStepData("careerStartDate", "Egal")} fill={stepData.careerStartDate === "Egal" ? "solid" : `outline`}>Alle
											anzeigen</IonButton>
										<IonButton onClick={() => setStepData("careerStartDate", "Sofort")}
												   fill={stepData.careerStartDate === "Sofort" ? "solid" : `outline`}>Sofort</IonButton>
										<IonButton onClick={() => setStepData("careerStartDate", "nächstes Ausbildungsjahr")}
												   fill={stepData.careerStartDate === "nächstes Ausbildungsjahr" ? "solid" : `outline`}>nächstes Ausbildungsjahr</IonButton>
										<IonButton onClick={() => setStepData("careerStartDate", "SELECT_DATE")}
												   fill={stepData.careerStartDate === "SELECT_DATE" ? "solid" : `outline`}>Datum wählen</IonButton>

                                        {stepData.careerStartDate === "SELECT_DATE" && <div className={"pb-2"}>
											<DatePicker onChange={(e: FormEvent) => {
                                                // @ts-ignore
                                                setStepData("careerStartDateValue", e.detail.value)
                                                // @ts-ignore
                                                //console.log(e.detail.value)
                                            }}>
											</DatePicker>
										</div>}
									</div>
								</IonItem>
							</IonList>

							<IonList className="list-custom">
								<IonListHeader>
									<IonLabel>Deine Details</IonLabel>
								</IonListHeader>
								<IonItem lines="none">

									<form className="w-full" onSubmit={() => {
                                    }}>
										<div className="py-2 text-sm">

											<div className="flex flex-row items-center justify-center">
												<UploadItem
													type={"Bewerbungsfoto"}
													uploads={stepData.uploads}
													onChange={(type, url) => {
                                                    }}/>
											</div>
											<FormInput value={session.email} name={"E-Mail"} title={"E-Mail"} onChange={() => {
                                            }} disabled/>
											<FormInput value={stepData.firstName} name={"firstName"} title={"Vorname"} onChange={setStepData}/>
											<FormInput value={stepData.lastName} name={"lastName"} title={"Nachname"} onChange={setStepData}/>
											<FormInput value={stepData.birthdate} name={"birthdate"} type={"date"} autocomplete={"bday-day"} title={"Geburtsdatum"}
													   onChange={setStepData}/>
                                            {/*<FormPhoneInput/>*/}
											<FormInput value={stepData.phone} disabled name={"phone"} autocomplete={"tel"} title={"Telefon"} onChange={setStepData}/>
										</div>
                                        {/*<FormInput name={"avatar"} title={ "Profilbild" } onChange={ setStepData }/>*/}
									</form>
                                    {/*<IonTextarea rows={3} value={ aboutMe } onIonChange={ e => setAboutMe(e.detail.value as string) }/>*/}
								</IonItem>
							</IonList>


                            {/*DEINE LAUFBAHN*/}
							<IonList className="list-custom">
								<IonListHeader>
									<IonLabel>Berufserfahrung</IonLabel>
								</IonListHeader>
								<IonItem>
									<div className="flex flex-col w-full py-2">
										<div>
											<IonButton expand={"block"} onClick={() => setStepData("careerCurrentExperience", "ENTRY")}
													   fill={stepData.careerCurrentExperience === "ENTRY" ? "solid" : `outline`}>Berufseinstieg</IonButton>
											<IonButton expand={"block"} onClick={() => setStepData("careerCurrentExperience", "INTERMEDIATE")}
													   fill={stepData.careerCurrentExperience === "INTERMEDIATE" ? "solid" : `outline`}>Erfahren</IonButton>
											<IonButton expand={"block"} onClick={() => setStepData("careerCurrentExperience", "SENIOR")}
													   fill={stepData.careerCurrentExperience === "SENIOR" ? "solid" : `outline`}>Senior</IonButton>
										</div>
									</div>
								</IonItem>
							</IonList>

                            {/*DEIN ABSCHLUSS*/}
							<IonList className="list-custom">
								<IonListHeader>
									<IonLabel>Deine Karriere bis jetzt.</IonLabel>
								</IonListHeader>
								<IonItem>
									<div className="flex flex-col w-full py-2">
										<form className="" onSubmit={() => {

                                        }}>
											<div className="border-bottom">
												<IonRadioGroup value={stepData.degreeCurrent} onIonChange={e => setStepData("degreeCurrent", e.detail.value)}>
													<FormSwitchButton fieldName={"careerCurrentDegree"} value={"Kein Abschluss"}/>
													<FormSwitchButton fieldName={"careerCurrentDegree"} value={"Hauptschulabschluss"}/>
													<FormSwitchButton fieldName={"careerCurrentDegree"} value={"Realschulabschluss"}/>
													<FormSwitchButton fieldName={"careerCurrentDegree"} value={"Abitur"}/>
													<FormSwitchButton fieldName={"careerCurrentDegree"} value={"Bachelor"}/>
													<FormSwitchButton fieldName={"careerCurrentDegree"} value={"Master"}/>

                                                    {/*<FormSwitchButton fieldName={ "careerCurrentDegree" } value={ "Noch in schulischer Ausbildung" }/>*/}
													<FormSwitchButton fieldName={"careerCurrentDegree"} value={"Ausbildung"}/>
													<FormSwitchButton fieldName={"careerCurrentDegree"} value={"Meister"}/>
													<FormSwitchButton fieldName={"careerCurrentDegree"} value={"Sonstiges"}/>

												</IonRadioGroup>
											</div>
											<div className="pt-2">
                                                {isAcademic && <>
													<FormInput name={"lastDegreeTitle"} title={"Dein Studiengang"} onChange={setStepData}/>
												</>}

                                                {isApprentice && <>
													<FormInput name={"lastDegreeTitle"} title={"Deine Ausbildung"} onChange={setStepData}/>
												</>}

                                                {isMaster && <>
													<FormInput name={"lastDegreeTitle"} title={"Dein Meister"} onChange={setStepData}/>
												</>}

                                                {/*<FormInput name={ "lastJobTitle" } title={ "Dein letzter Job" } onChange={ setStepData }/>*/}

											</div>

											<div className="pt-2">
												<FormInput value={stepData.lastEmployerTitle} name={"lastEmployerTitle"} title={"Dein letzter Arbeitgeber"} onChange={setStepData}/>
                                                {/*DOESNT WORK - onChange not */}
												{/*<div className="pl-4 w-full py-2 flex flex-col ">*/}
                                                {/*    <div className="text-xs text-gray-500 uppercase">Seit</div>*/}
												{/*	<DatePicker*/}
												{/*		showDefaultButtons={true}*/}
                                                {/*        ionChange={(e:any) => {*/}
                                                {/*            console.log("CHANGE", e)*/}
                                                {/*        }}*/}
                                                {/*        placeholder={"Datum"}*/}
                                                {/*        onIonChange={(e: any) => {*/}
                                                {/*            console.log("DATE", e.detail.value)*/}
                                                {/*        }}*/}
                                                {/*        onChange={(e: any) => {*/}
                                                {/*            console.log("DATE REG", e.detail.value)*/}
                                                {/*        }}*/}
                                                {/*        onBlur={(e: any) => {*/}
                                                {/*            setStepData("lastEmployerStartDate", e.detail.value)*/}
                                                {/*            console.log(e)*/}
                                                {/*            console.log(e.target.value)*/}
                                                {/*            console.log("DATE BLU", e.detail.value)*/}
                                                {/*        }}>*/}
												{/*	</DatePicker>*/}
												{/*</div>*/}
											</div>
										</form>
									</div>
								</IonItem>
							</IonList>

							<IonList className="list-custom">
								<IonListHeader>
									<IonLabel>Was wünscht Du Dir von Deinem Arbeitgeber?</IonLabel>
								</IonListHeader>
								<IonItem lines="none" detail onClick={handleOpenPassions}>
									<IonLabel>
										<div className="text-xs">
                                            {stepData.seekTraits?.join(", ")}
										</div>
									</IonLabel>
								</IonItem>
							</IonList>

						</div>
                    }

                    {/*{*/}
                    {/*    segmentView === 'PREVIEW' &&*/}
                    {/*  <div className="segment-view preview-container full-height">*/}
                    {/*    <div className="card-border full-height" ref={ stackRef }>*/}
                    {/*      <SwipeCard*/}
                    {/*        user={ user }*/}
                    {/*        isPreview*/}
                    {/*        onNoMoreSlide={ handleNoMoreSlide }*/}
                    {/*      />*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*}*/}

                    <div className="pb-2">&nbsp;</div>
                </div>


                <IonModal isOpen={isPassionsOpen}>
                    <Passions
                        onClose={() => setIsPassionsOpen(false)}
                    />
                </IonModal>
            </IonContent>
        </>
    );
};

ProfileEdit.defaultProps = {}

export default ProfileEdit;
