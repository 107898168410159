import React, {useEffect, useMemo} from 'react';
import {IonButton, IonContent, IonInput, IonItem, IonLabel, IonPage, IonRadio} from '@ionic/react';
import {Swiper, SwiperSlide, useSwiper, useSwiperSlide} from 'swiper/react';
import {Pagination} from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "./Onboarding.scss"
import '@ionic/react/css/ionic-swiper.css';
import StepDetails from './step/StepDetails';
import StepCareer from './step/StepCareer';
import StepDegree from './step/StepDegree';
import StepTraining from './step/StepTraining';
import StepProfile from './step/StepProfile';
import useUser from '../../store/useUser';
import StepPersonal from './step/StepPersonal';
import useJobSeeker from '../../store/useJobSeeker';
import StepDocuments from "pages/Onboarding/step/StepDocuments";
import StepPhoneVerification from "pages/Onboarding/step/StepPhoneVerification";
import toast from "react-hot-toast";
import StepSwipeExplainer from "pages/Onboarding/step/StepSwipeExplainer";


export const FormSwitchBucketButton = ({removeOnly = false, active = true, fieldName = "", value = "", text = "", ...rest}) => {
    const setStepData = useUser(state => state.setStepData)
    const stepData = useUser(state => state.stepData)
    const bucket = stepData[fieldName] || []
    const isActive = bucket.includes(value)
    const onChange = () => {
        if (isActive) {
            setStepData(fieldName, bucket.filter((e: string) => e !== value))
        } else {
            !removeOnly && setStepData(fieldName, [...bucket, value])
        }
    }

    const isDisabled = (!active && !isActive) || (!isActive && removeOnly)
    return <IonButton disabled={isDisabled} expand={"block"} onClick={onChange} fill={isActive ? "solid" : "outline"} {...rest}>{text || value}</IonButton>
}

export const FormSwitchButton = ({fieldName = "", value = "", text = "", ...rest}) => {
    const setStepData = useUser(state => state.setStepData)
    const stepData = useUser(state => state.stepData)

    const onChange = useMemo(() => {
        return () => setStepData(fieldName, value)
    }, [fieldName, value])
    return <IonButton expand={"block"} onClick={onChange} fill={stepData[fieldName] === value ? "solid" : "outline"} {...rest}>{text || value}</IonButton>
}

export const FormRadioItem: React.FC<{ title: string, value?: string }> = ({title, value}) => {
    return <IonItem>
        <IonLabel>{title} </IonLabel>
        <IonRadio slot="start" value={value || title}/>
    </IonItem>
}

export const FormInput: React.FC<{ hideLabel?: boolean, useFragment?: boolean, title?: string, name: string, onChange: Function, [x: string]: any }>
    = ({
           hideLabel = false,
           useFragment = false,
           title = "Eingabe",
           name,
           onChange,
           ...props
       }) => {
    const Wrapper = useFragment ? React.Fragment : IonItem
    return <Wrapper>
        {!hideLabel && <IonLabel position="floating"><span className="text-sm">{title}</span></IonLabel>}
        <IonInput {...props}
                  placeholder={title}
                  onIonChange={e => {
                      let value = e.target.value
                      onChange(name, value, e)
                  }}/>
    </Wrapper>
}

export const SlideContent: React.FC<{ title?: string, canContinue?: boolean, onClick?: Function }>
    = ({children, title = "", onClick, canContinue = true}) => {
    const swiper = useSwiper()
    const slide = useSwiperSlide()

    useEffect(() => {
        if(slide.isActive ){
            if(canContinue === undefined) return swiper.enable()
            if(!canContinue){
                swiper.disable()
            } else {
                swiper.enable()
            }
        }
    }, [slide, canContinue])
    return <div className="px-4 h-full pt-4 pb-12 max-w-full min-w-[340px] w-[520px]">
        <div className=" h-full text-gray-700 p-6 rounded-2xl text-left flex flex-col justify-between border border-gray-300 max-h-full overflow-y-auto bg-white">
            <div>
                {title && <div className="text-4xl font-bold  pb-4">{title}</div>}
                <div className="text-base">
                    {children}
                </div>
            </div>

            <div className="pt-4">
                <IonButton fill={canContinue ? "solid" : "clear"} color={canContinue? undefined: "black"} expand={"block"} onClick={() => {
                    if(!canContinue) {
                        toast.error("Bitte fülle alle Felder aus.")
                        return
                    }
                    onClick && onClick()
                    !onClick && swiper.slideNext()
                }}>Weiter</IonButton>
            </div>
        </div>
    </div>
}

const Onboarding: React.FC = () => {

    const stepData = useUser(state => state.stepData)
    const setStepData = useUser(state => state.setStepData)
    const jobSeeker = useJobSeeker()
    const hasOfferings = stepData.brancheCurrent === "Soziales" || stepData.brancheCurrent === "Pflege" || stepData.brancheCurrent === "Handwerk"

    return (
        <IonPage className={""}>
            <IonContent className={"bg-gradient"}>
                <Swiper
                    pagination
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    {/*welcome*/}
                    <SwiperSlide>
                        <SlideContent title={"Willkommen bei Skilled"}>
                            <div className="text-base">Wir stellen Dir ein paar Fragen und Du gibst die passenden Antworten. In 1-2 Minuten wird Dir der erste Job angezeigt.</div>
                        </SlideContent>
                    </SwiperSlide>


                    {/*PERSÖNLICHES*/}
                    <SwiperSlide>
                        <StepPersonal/>
                    </SwiperSlide>

                    {/*PERSÖNLICHES*/}
                    <SwiperSlide>
                        <StepDetails/>
                    </SwiperSlide>


                    {/*PERSÖNLICHES*/}
                    <SwiperSlide>
                        <StepPhoneVerification/>
                    </SwiperSlide>

                    {/*LAUFBAHN*/}
                    <SwiperSlide>
                        <StepCareer/>
                    </SwiperSlide>

                    {/* ABSCHLUSS*/}
                    <SwiperSlide>
                        <StepDegree/>
                    </SwiperSlide>

                    {/* ABSCHLUSS*/}
                    <SwiperSlide>
                        <StepTraining/>
                    </SwiperSlide>

                    {/* ANLAGEN*/}
                    <SwiperSlide>
                        <StepDocuments/>
                    </SwiperSlide>

                    <SwiperSlide>
                        <SlideContent title={"VIELEN DANK!"}>
							Danke, dass du deinen Fragebogen ausgefüllt hast. Du kannst später auch ein weiteres Profil anlegen um dich z.B für ein Praktikum oder Mini-Job zu
							bewerben. Nun kannst du mit dem Wischen loslegen.
						</SlideContent>
                    </SwiperSlide>

                    <SwiperSlide>
                       <StepSwipeExplainer/>
                    </SwiperSlide>

                    <SwiperSlide>
                        <SlideContent title={"Weiter geht's?"}>
                            <div className="prose">
                                <p> Skilled wurde mit einem Algorithmus entwickelt, welcher dir die passenden Stellenanzeigen findet. </p>
                                <p> Hierfür errechnen wir einen Übereinstimmungs-Score. Dieser setzt sich aus deinen Antworten und den Antworten des Unternehmens aus dem Fragebogen
                                    zusammen.</p>
                            </div>
                        </SlideContent>
                    </SwiperSlide>


                    {/*<SwiperSlide>*/}
                    {/*    <StepTraits/>*/}
                    {/*</SwiperSlide>*/}

                    <SwiperSlide>
                        <StepProfile/>
                    </SwiperSlide>

                    {/*<SwiperSlide>*/}

                    {/*    <div className="text-left">*/}
                    {/*        <div>DEBUG ZUSAMMENFASSUNG</div>*/}
                    {/*        <pre className="text-xs">{ JSON.stringify(stepData, null, 2) }</pre>*/}
                    {/*    </div>*/}
                    {/*</SwiperSlide>*/}

                    {/*<SwiperSlide>*/}
                    {/*    <div className="text-center">*/}
                    {/*        /!*<div>{ stepData.degreeCurrent } Anzeigen</div>*/}
                    {/*        <Link*/}
                    {/*            onClick={ ()=>{*/}
                    {/*                setStepData("hasFinishedWizzard", new Date().toISOString())*/}
                    {/*            } }*/}
                    {/*            to={ "/tabs/explore" }><IonButton expand={ "block" }>Stellen anzeigen</IonButton></Link>*/}
                    {/*    </div>*/}
                    {/*</SwiperSlide>*/}


                </Swiper>
            </IonContent>
        </IonPage>
    );
};
export default Onboarding;