import React from 'react';
import {useSwiper} from 'swiper/react';
import {FormSwitchButton, SlideContent} from '../Onboarding';
import useUser from '../../../store/useUser';
import {IonLabel} from '@ionic/react';
import useStaticData from "store/useStaticData";

const StepTraining: React.FC<{ title?: string }> = ({children, title = ""}) => {
    const swiper = useSwiper()
    const setStepData = useUser(state => state.setStepData)
    const stepData = useUser(state => state.stepData)
    const jobDomains = useStaticData(state => state.jobDomains)

    return <SlideContent title={"Dein Traumjob"} canContinue={stepData.brancheCurrent}>
        <div>
            <form className="" onSubmit={() => {
            }}>

                <IonLabel>Deine Branche</IonLabel>
                {jobDomains.map(jobDomain => {
                    return <FormSwitchButton size={"small"} key={jobDomain.name} fieldName={"brancheCurrent"} value={jobDomain.name}/>
                })}

            </form>
        </div>
    </SlideContent>
}

export default StepTraining