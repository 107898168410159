import {Plugins} from '@capacitor/core';
import toast, {ToastOptions} from "react-hot-toast"

const {LocalNotifications} = Plugins;

class Notifications {

    public toast = toast

    public show(message: string, options?: ToastOptions) {
        return toast(message, options);

    }

    public async schedule(hour: number, minute: number) {
        try {
            // Request/ check permissions
            if (!(await LocalNotifications.requestPermission()).granted) return;

            // Clear old notifications in prep for refresh (OPTIONAL)
            const pending = await LocalNotifications.getPending();
            if (pending.notifications.length > 0)
                await LocalNotifications.cancel(pending);

            await LocalNotifications.schedule({
                notifications: [{
                    title: 'SKILLED',
                    body: "TEST SKILLED MESSAGE",
                    id: "i",
                    schedule: {
                        on: { // swap this out for at or every as needed
                            hour,
                            minute
                        }
                    }
                }]
            });
        } catch (error) {
            console.error(error);
        }
    }
}

const notifications = new Notifications()


notifications.schedule(20, 0)

// @ts-ignore
window.notifications = notifications
export default notifications