import {IJobMatch} from '../pages/Discover/Discover';

export interface ICompanyLink {
    _id: string;
    link: string;
    display: string;
}


export enum EMatchState {
    "NONE" = "NONE",
    "STORED" = "STORED",
    "REQUESTED" = "REQUESTED",
    "CONTACTED" = "CONTACTED",
    "CANCELED" = "CANCELED",
    "REJECTED" = "REJECTED",
    "TRASHED" = "TRASHED",
}


class MatchingScore {
    score: number
    color: string

    constructor(score: any) {
        this.score = score
        this.color = this.score > 75 ? "#d8ff2b" : "#ffaa46"
    }

}

export default class JobProfile {
    id: string
    jobTitle: string;
    jobType: string[];
    jobIncome: string;
    state?: EMatchState
    jobSector: {
        _id: string,
        display: string,
        name: string
    };
    company: ICompanyLink;
    images: any[] = [];
    jobDescription: string;
    _mby: string;
    _by: string;
    _modified: number;
    _created: number;
    _id: string;
    jobDepartment: string;
    jobFeatures: string;
    jobRequirements: string;
    traits: { [key: string]: boolean } = {}
    jobLocation: string;
    jobTraits: string[];
    jobAnsprechpartner: string;
    jobImage?: any;
    jobSectorName: string

    matchingScore: MatchingScore
    match: IJobMatch | null
    matchId: string | null

    constructor(jobProfileData: any) {
        Object.assign(this, jobProfileData)
        this.id = jobProfileData._id
        this.jobTitle = jobProfileData.jobTitle
        this.jobType = jobProfileData.jobType
        this.jobSector = jobProfileData.jobSector
        this.jobIncome = jobProfileData.jobIncome
        this.company = jobProfileData.company
        this.jobDescription = jobProfileData.jobDescription
        this._mby = jobProfileData._mby
        this._by = jobProfileData._by
        this._modified = jobProfileData._modified
        this._created = jobProfileData._created
        this._id = jobProfileData._id
        this.jobDepartment = jobProfileData.jobDepartment
        this.jobFeatures = jobProfileData.jobFeatures
        this.jobRequirements = jobProfileData.jobRequirements
        this.jobLocation = jobProfileData.jobLocation
        this.jobTraits = jobProfileData.jobTraits
        this.jobAnsprechpartner = jobProfileData.jobAnsprechpartner
        this.jobImage = jobProfileData.jobImage
        this.match = jobProfileData.match || null
        this.matchId = jobProfileData.matchId || null
        this.images = [{
            id: 0,
            imageUrl: "assets/img/card-placeholder.jpg"
        }]
        this.matchingScore = new MatchingScore(jobProfileData.matchingScore)

        this.jobSectorName = this.jobSector.display || this.jobSector.name
    }

    setCompanyLogo(url: string) {
        this.images[0].imageUrl = url
    }
}




