import { IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { Link } from 'react-router-dom';
import { clipboardOutline } from 'ionicons/icons';
import React from 'react';
import useUser from '../../store/useUser';

const Badge: React.FC<{}> = ({ children })=>{
    return <div
        className="absolute top-1 w-4 h-4 text-center right-1 text-xs text-white bg-yellow-700 rounded-2xl ">{ children }</div>
}

export default function HeaderMain({ title = "Jobs" }) {
    const matches = useUser(state=>state.matches) || []
    const interestingMatches = useUser(state=>state.interestingMatches) || []
    const notifications = useUser( state => state.notifications)
    const session = useUser(state=>state.session)
    const stepData = useUser(state=>state.stepData)
    const stored = matches.map(m=>m.matchStateSeeker)
    return <IonHeader className='no-border'>
        <IonToolbar className='explore-toolbar toolbar-no-border toolbar-no-safe-area'>
            <IonButtons slot='start'>
                <Link to={ "/userprofile" }>
                    <div
                        className="rounded-full bg-black text-white w-8 h-8 flex flex-row items-center justify-around font-bold text-xs">
                        { stepData.firstName.slice(0, 1) }
                    </div>
                    {notifications.length > 0 && <div className="absolute top-1 right-[-8px] bg-red-600 rounded-full text-white text-xs items-center justify-center flex flex-row h-4 w-4">{notifications.length}</div>}

                </Link>
            </IonButtons>

            <IonTitle className='ion-text-center'>
                { title }
            </IonTitle>

            <IonButtons slot="end">
                <div className="relative">
                    <Link to={ "/tabs/discover" }>
                        <IonIcon icon={ clipboardOutline } slot="start"/>
                        <Badge>{ interestingMatches.length }</Badge>
                    </Link>
                </div>
            </IonButtons>

        </IonToolbar>
    </IonHeader>
}