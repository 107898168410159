import React, {useEffect, useState} from "react";
import api from "store/api";
import {IonIcon} from "@ionic/react";
import {closeCircle} from "ionicons/icons";
import {not} from "rxjs/internal/util/not";
import useUser from "store/useUser";
import {Link} from "react-router-dom";

export interface INotification {
    user: string;
    message: string;
    ctaName?: any;
    ctaLink?: any;
    _by?: any;
    _modified: number;
    jobSeeker?: any;
    notifyEmail: boolean;
    notifyPush: boolean;
    notifyRich: boolean;
    _created: number;
    _id: string;
}

export default function NotificationsList() {

    const notifications = useUser(state => state.notifications)
    const deleteNotification = useUser(state => state.deleteNotification)
    const flag = 0

    const notificationPreview = notifications.slice(0, 4)
    const hasMore = notifications.length > 4

    return <div className="px-4 grid grid-cols-1 gap-4 mt-4">
        {notifications.length === 0 && <div
			className="text-center p-4 border border-2 rounded-lg border-dashed border-gray-300 text-gray-500 uppercase font-bold text-gray-400">Keine
			Mitteilungen</div>}
        {notifications.map(notification => {
                return <Link to={notification.ctaLink || "/tabs/explore"}
                             onClick={() => {
                                 deleteNotification(notification._id)
                             }}
                             className="bg-yellow-500 text-white p-4 text-sm rounded-lg"
                             key={notification._id}>
                    <div className="flex flex-row justify-between items-center">
                        <div>
                            {notification.message}
                        </div>
                        <IonIcon
                            className={"text-2xl"} icon={closeCircle}/>
                    </div>
                </Link>
            }
        )}
    </div>
}