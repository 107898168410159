import React from 'react';
import {useSwiper} from 'swiper/react';
import {FormSwitchButton, SlideContent} from '../Onboarding';
import useUser from '../../../store/useUser';
import {IonItem, IonLabel} from '@ionic/react';
import {UploadItem} from "pages/ProfileEdit/ProfileEdit";

const StepTraining: React.FC<{ title?: string }> = ({children, title = ""}) => {
    const swiper = useSwiper()
    const stepData = useUser(state => state.stepData)
    const setStepData = useUser(state => state.setStepData)

    const documents = stepData.uploads?.documents || []
    return <SlideContent title={"Dein Traumjob"} canContinue={stepData.brancheCurrent}>
        <div>
            <form className="" onSubmit={() => {

            }}>

                <IonLabel>Deine Anhänge</IonLabel>
                <IonItem className="ion-no-padding overflow-visible">
                    <UploadItem
                        type={"Lebenslauf"}
                        uploads={stepData.uploads}
                        onChange={(type, url) => {
                            //console.log({documents})
                        }}/>
                </IonItem>

                <IonItem className="ion-no-padding overflow-visible">
                    <UploadItem
                        type={"Zeugnis"}
                        uploads={stepData.uploads}
                        onChange={(type, url) => {
                            //console.log({documents})
                        }}/>
                </IonItem>

                <IonItem className="ion-no-padding overflow-visible">
                    <UploadItem
                        type={"Zertifikate (A)"}
                        uploads={stepData.uploads}
                        onChange={(type, url) => {
                        }}/>
                </IonItem>

                <IonItem className="ion-no-padding overflow-visible">
                    <UploadItem
                        type={"Zertifikate (B)"}
                        uploads={stepData.uploads}
                        onChange={(type, url) => {
                        }}/>
                </IonItem>


                <IonItem className="ion-no-padding overflow-visible">
                    <UploadItem
                        type={"Weitere Datei hochladen"}
                        uploads={stepData.uploads}
                        onChange={(type, url) => {
                        }}/>
                </IonItem>

            </form>
        </div>
    </SlideContent>
}

export default StepTraining