import React, {useEffect, useState} from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonIcon,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import {chevronBack, listOutline, newspaperOutline, trophyOutline,} from "ionicons/icons";
import Settings from "../Settings/Settings";
import Profile from "../Profile/Profile";
import ProfileEdit from "../ProfileEdit/ProfileEdit";
import "./UserProfile.scss";
import USERS from "../Explore/users.dummy";
import useUser from '../../store/useUser';
import {Link} from "react-router-dom";
import {ProfileStatusCard} from './ProfileStatusCard';
import NotificationsList from "components/NotificationsList";

type Props = {
    history: any;
};


const UserProfile: React.FC<Props> = ({history}) => {
    const session = useUser(state => state.session)
    const stepData = useUser(state => state.stepData)
    const logout = useUser(state => state.logout)
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
    const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);
    const [isProfileEditOpen, setIsProfileEditOpen] = useState<boolean>(false);
    const [isTinderGoldOpen, setIsTinderGoldOpen] = useState<boolean>(false); // :D
    let [percentage, setPercentage] = useState(0);
    const user = USERS[3];
    const spentvalue = 6390;
    const endPercentage = 60;

    useEffect(() => {
    }, []);

    const handleViewEditProfile = () => {
        setIsProfileEditOpen(true);
    };

    if (!session) return null

    return (
        <IonPage>
            <IonHeader className="no-border">
                <IonToolbar className="user-profile-toolbar toolbar-no-border ">
                    <IonButtons slot="start">
                        <IonBackButton text={""} icon={chevronBack} defaultHref="tabs"/>
                    </IonButtons>
                    <IonTitle className="ion-text-center">Profil</IonTitle>
                    <IonButtons slot="end">
                        <IonButton></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent force-overscroll="true" className="me-page bg-white">

                <div className="">
                    <div className="section-upper">
                        <div className="me-header" >
                            <div className="detail-holder">
                                <span className="me-title">{stepData.firstName} {stepData.lastName}</span>
                                {/*<span className="icon-verified">*/}
                                {/*  <IonIcon icon={checkmarkOutline} />*/}
                                {/*</span>*/}
                            </div>
                            <div className="me-level"> {stepData.jobType}</div>
                        </div>

                        <IonRow className="ion-justify-content-center ion-align-items-center">
                            <IonCol size="auto">
                                <IonRow className="me-button-group">
                                    <IonCol className="ion-text-center">
                                        <IonButton
                                            onClick={handleViewEditProfile}
                                            color="white"
                                            className="button-custom button-icon"
                                        >
                                            <IonIcon icon={newspaperOutline} slot="icon-only"/>
                                        </IonButton>
                                        <div className="button-label">Dein Profil</div>
                                    </IonCol>
                                    <IonCol className="ion-text-center">
                                        <Link to={`/tabs/explore`}>
                                            <div className="button-stack primary">
                                                <IonButton
                                                    className="button-custom button-lg button-icon button-brand"
                                                >
                                                    <IonIcon icon={trophyOutline} slot="icon-only"/>
                                                </IonButton>
                                            </div>
                                            <div className="button-label">Home</div>
                                        </Link>
                                    </IonCol>
                                    <IonCol className="ion-text-center">
                                        <Link to={`/tabs/discover`}>
                                            <IonButton
                                                color="white"
                                                className="button-custom button-icon"
                                                // onClick={handleViewEditProfile}
                                            >
                                                <IonIcon icon={listOutline} slot="icon-only"/>
                                            </IonButton>
                                        </Link>
                                        <div className="button-label">Matches</div>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </div>

                    <div className="px-4 pt-4">
                        <ProfileStatusCard onClick={handleViewEditProfile}/>
                    </div>
                    <div className="section-lower">

                        <div className="py-4">
                            {/*<div className="px-4 font-bold">Neuigkeiten</div>*/}
                            <NotificationsList/>
                        </div>

                        <div className="p-4 pt-12">
                            {/*<IonButton fill={ "outline" } shape={ "round" } expand={ "full" }>Papierkorb</IonButton>*/}
                            <a href="https://skilledapp.de/datenschutz" target={"_blank"}><IonButton fill={"outline"} shape={"round"} expand={"full"}>Datenschutz</IonButton></a>
                            <a href="https://skilledapp.de/#faqs" target={"_blank"}><IonButton fill={"outline"} shape={"round"} expand={"full"}>Service und
                                Hilfe</IonButton></a>
                            <div className="pt-4">
                                <IonButton
                                    onClick={logout}
                                    fill={"outline"} shape={"round"} expand={"full"}>Abmelden</IonButton>
                            </div>

                        </div>

                    </div>
                </div>

                <IonModal isOpen={isSettingsOpen}>
                    <Settings history={history} onClose={() => setIsSettingsOpen(false)}/>
                </IonModal>

                <IonModal isOpen={isProfileOpen} canDismiss>
                    <Profile user={user} onClose={() => setIsProfileOpen(false)}/>
                </IonModal>

                <IonModal isOpen={isProfileEditOpen}>
                    <ProfileEdit user={user} onClose={() => setIsProfileEditOpen(false)}/>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

UserProfile.defaultProps = {};

export default UserProfile;
