import React from "react";
import {SlideContent} from "pages/Onboarding/Onboarding";
import {useSwiperSlide} from "swiper/react";


export default function () {
    const slide = useSwiperSlide()

    return <SlideContent title={"Das Wisch-Prinzip"}>
        So wie du es bereits von anderen namhaften Apps kennst bieten wir dir einfache Bewerbungen mit einem einfachen Wisch.
        <div className="pt-9">
            {slide.isActive && <video autoPlay muted playsInline>
				<source src="/how-to-swipe.mp4" type="video/mp4" />
			</video>}
        </div>
    </SlideContent>
}