import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonButton, IonIcon, IonToolbar } from '@ionic/react';
import { checkmarkCircle, chevronDown, chevronUp, downloadSharp, heartOutline } from 'ionicons/icons';
import "pages/Chat/Chat.scss"
import InputWithSkilled from '../InputWithGiphy/InputWithSkilled';
import { SocketContext } from '../../App';
import useUser from '../../store/useUser';
import moment from 'moment';
import api from '../../store/api';
import { Link } from 'react-router-dom';

const MESSAGES: Array<any> = [];


type Props = {
    match: any
}

const VibeChat: React.FC<Props> = ({ match })=>{
    const stepData = useUser(state=>state.stepData)
    const [ extra, setExtra ] = useState(false)
    const [ messages, setMessages ] = useState<any[]>(MESSAGES);
    const contentRef = useRef<HTMLIonContentElement>(null);

    const authorName = `${ stepData.firstName } ${ stepData.lastName }`

    const socket = useContext(SocketContext)
    const jobMatchId = match?._id

    useEffect(()=>{
        try {
            const chat = JSON.parse(match.chat);
            //console.log({ chat })
            if ( chat ) {
                let chatHistory = chat.map((item: any)=>{
                    return {
                        id: item.id,
                        text: item.text,
                        isSender: item.author === `${ authorName }`,
                        author: { name: item.author },
                        timestamp: moment(item.timeReceived).format("HH:mm [Uhr]"),
                        type: item.type || "text"
                    };
                });
                setMessages(prev=>{
                    return [ ...prev, ...chatHistory ];
                });
            }
        } catch ( err ) {
            console.error("FAILED RECOVERING CHAT");
        }
    }, [ jobMatchId ]);


    useEffect(()=>{
        //console.log("SUB", jobMatchId, { socket })
        if ( socket ) {
            socket.emit("subscribe:match", { jobMatchId })
            socket.on("message", (data)=>{
                //console.log("MESSAGE REC", data)
                setMessages(prev=>[ ...prev, {
                    id: prev.length,
                    isSender: false,
                    type: data.type, // 'text' or 'image' or file
                    text: data.type.toUpperCase() === 'IMAGE' ? data.imageUrl : data.text,
                    timestamp: moment(data.timeReceived).format("HH:mm [Uhr]")
                } ]);
            })
        }
    }, [ socket ])

    useEffect(()=>{
        scrollToBottom(0, true);
    })

    const handleSubmitMessage = (data: any)=>{
        //console.log("SUBMIT", { data })
        setMessages(prev=>[ ...prev, {
            id: prev.length,
            isSender: true,
            type: data.type, // 'text' or 'image' or file
            text: data.type.toUpperCase() === 'IMAGE' ? data.imageUrl : data.text,
            timestamp: 'Mar 30, 2023 9:55am'
        } ]);
        const message = {
            jobMatchId,
            text: data.text,
            author: `${ stepData.firstName } ${ stepData.lastName }`,
            type: data.type
        };
        socket && socket.emit("message", message)

        setTimeout(()=>{
            scrollToBottom();
        });
    }

    const scrollToBottom = (duration: number = 500, isFirstLoad: boolean = false)=>{
        if ( contentRef.current ) {
            ////console.log("BOTTOM", contentRef.current.scrollTop, contentRef.current.scrollHeight)
            if ( isFirstLoad ) {
                setTimeout(()=>{
                    if ( contentRef && contentRef.current ) {
                        // @ts-ignore
                        contentRef.current.scrollTop = contentRef.current.scrollHeight
                    }
                }, 500);
            } else {
                if ( contentRef && contentRef.current ) {
                    // @ts-ignore
                    contentRef.current.scrollTop = contentRef.current.scrollHeight
                }
            }
        }
    }

    const nl2br = (text: string)=>{
        if ( !text ) return text;
        return text.replace(/\n/ig, '<br>');
    }

    return (
        <div className="VibeChat">


            { /* @ts-ignore: TS2739 */ }
            <div className="chat-container" ref={ contentRef }>
                {/*<div className="ion-padding ion-text-center match-info">*/ }
                {/*    <IonText color="medium">YOU MATCHED WITH Pflege Herford GmbH ON 03/30/2021</IonText>*/ }
                {/*</div>*/ }

                <div className="chat-list pb-12">
                    {/*TODO: DISPLAY UNREAD MESSAGE & DAYS*/ }
                    {/*<div className="chat-timestamp center">*/ }
                    {/*    <strong>DO, 11 Nov,</strong> 12:38 Uhr*/ }
                    {/*</div>*/ }

                    <div className="absolute bottom-[55px] z-40 flex flex-col justify-end right-2">
                        { extra && <div>
                            { stepData.CV &&
                              <IonButton onClick={ ()=>{
                                  // handleSubmitMessage({
                                  //     type: "text",
                                  //     text: `Anbei finden Sie meinen Lebenslauf:  ${ stepData.CV }`
                                  // })
                                  handleSubmitMessage({
                                      type: "file",
                                      link: `/api/${ stepData.CV }`,
                                      text: "Lebenslauf herunterladen"
                                  })
                                  api(``)
                              } } size="small">Lebenslauf senden</IonButton> }

                            { !stepData.CV && <Link to={ "/userprofile" }>
                              <IonButton size="small">
                                Lebenslauf hinterlegen
                              </IonButton>
                            </Link> }

                        </div> }
                        <div className="ml-auto">
                            <IonButton className={"group"} onClick={ ()=>{
                                setExtra(!extra)
                            } } size="small"><IonIcon className="group-hover:rotate-[180deg] transition-all duration-[320ms]" icon={ extra ? chevronUp : chevronDown }/></IonButton>
                        </div>
                    </div>
                    { messages.length === 0 && <div className="p-4 text-xs ">Das ist der Anfang Deines Chats mit <span className="b whitespace-pre">{ match.company.name }</span> <br/> Sag Hallo!</div> }
                    {
                        messages.map((item: any)=>(
                            <div className={ `chat-item${ item.isSender ? ' chat-item-outgoing' : '' }` } key={ item.id }>
                                <div className="chat-item-inner">


                                    <div className="chat-body">
                                        <div className={ `chat-item-bubble${ item.type === 'image' ? ' bubble-image' : '' }` }>
                                            {
                                                item.type === 'text' &&
                                              <div className="chat-text text-sm" dangerouslySetInnerHTML={ { __html: nl2br(item.text) } }/>
                                            }
                                            {
                                                item.type === 'image' &&
                                              <img src={ item.text } alt=""/>
                                            }
                                            {
                                                item.type === 'file' &&
                                              <div>
                                                  { stepData.CV && <div className="text-sm bg-green-600 text-white px-4 py-1 rounded-xl">
                                                    <div className="flex flex-row items-center">
                                                      <a download={ "Lebenslauf.pdf" } href={ `${ stepData.CV }` }>
                                                        <div>Lebenslauf.pdf</div>
                                                        <div className="text-xs">herunterladen</div>
                                                      </a>
                                                      <div className="pl-4">
                                                        <IonIcon icon={ downloadSharp }/>
                                                      </div>
                                                    </div>
                                                  </div> }
                                                  { !stepData.CV && <div className="text-sm bg-green-600 text-white px-4 py-1 rounded-xl">
                                                    <div className="flex flex-row items-center">
                                                      Lebenslauf nicht vorhanden
                                                    </div>
                                                  </div> }
                                              </div>
                                            }
                                        </div>

                                        {
                                            item.isSender &&
                                          <div className="chat-item-status">
                                            <IonIcon icon={ checkmarkCircle } color="secondary"/>gesendet
                                          </div>
                                        }
                                    </div>

                                    {
                                        !item.isSender && item.type === 'image' &&
                                      <div className="chat-item-reaction">
                                        <IonIcon icon={ heartOutline }/>
                                      </div>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className="fixed bottom-0 left-0 right-0">
                <IonToolbar className="toolbar-no-border">
                    <div className="flex flex-row items-center">
                        <div className="w-full">
                            <InputWithSkilled
                                onChange={ handleSubmitMessage }
                            />
                        </div>
                    </div>
                </IonToolbar>
            </div>
        </div>
    );
};

VibeChat.defaultProps = {}

export default VibeChat;
