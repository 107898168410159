const currency = (n: number | string, minimumFractionDigits = 2, alternative = "")=>{
    let priceNumber = Number(n)
    if ( !priceNumber ) return alternative
    return priceNumber.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'Eur',
        minimumFractionDigits
    });
    // return `${Number( n ).toFixed( decimals ).replace( ".", "," )} €`
}

export default currency
