import React, {useState} from 'react';

import {CreateAnimation, IonButton, IonCol, IonContent, IonGrid, IonModal, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSpinner,} from '@ionic/react';
import * as swing from 'swing';
import ReactSwing from 'react-swing';
import './Explore.scss';
import RippleLoader from 'components/RippleLoader/RippleLoader';
import Profile from '../Profile/Profile';
import {RouteComponentProps} from 'react-router';
import JobProfile, {EMatchState} from '../../model/JobProfile';
import SwipeJobCard from 'components/SwipeCard/SwipeJobCard';
import useJobMatch from 'store/useJobMatch';
import useUser from 'store/useUser';
import HeaderMain from 'components/Header/HeaderMain';
import {ProfileStatusCard} from '../UserProfile/ProfileStatusCard';
import {MatchStatusCard} from '../UserProfile/MatchStatusCard';
import {Link} from 'react-router-dom';
import NotificationsList from 'components/NotificationsList';


interface IProfileCard {
    className: string,
    jobs: JobProfile[],
    onClick: () => void
}

function JobProfilesCard({
                             className = "",
                             onClick = () => {
                             }
                         }: IProfileCard
) {
    const [loading, setLoading] = useState(false)
    const stepData = useUser(state => state.stepData)
    const jobs = useUser.getState().newJobProfiles
    const loadJobProfiles = useUser(state => state.loadJobProfiles)
    const jobType = stepData.jobType
    const hasJobs = jobs.length > 0
    return <div className={className}>
        <Link to={"/userprofile"}>
            <div className="p-4 rounded-xl shadow-md mb-4 ">
                <div className="rounded-xl text-black flex flex-row items-center justify-between">
                    <div>
                        <div className="text-xs">Branche</div>
                        <div className="text-sm">{stepData.brancheCurrent}</div>
                    </div>
                    <div>
                        <div className="text-xs">Art</div>
                        <div className="text-sm">{stepData.jobType}</div>
                    </div>
                </div>
            </div>
        </Link>


        {hasJobs && <button onClick={onClick} className="JobProfilesCard text-left bg-yellow-500  rounded-xl text-white w-full overflow-hidden">
			<div className="flex flex-col">
				<div className="p-8">
					<div className="text-4xl text-center">{stepData.brancheCurrent}</div>
				</div>
				<div className="bg-[rgba(0,0,0,0.05)] text-white px-4 py-2 text-xs text-center">
					<span className="font-bold">{jobs.length} {jobs.length !== 1 ? "Treffer" : "Treffer"} für {jobType}</span>
				</div>
			</div>
		</button>}

        {!hasJobs && <button
			onClick={async () => {
                setLoading(true)
                await loadJobProfiles()
                setTimeout(() => {
                    setLoading(false)
                }, 800)
            }}
			className="JobProfilesCard relative text-left bg-yellow-500  rounded-xl text-white w-full overflow-hidden">
            {loading && <div className="flex flex-row items-center h-full absolute left-0 right-0 bottom-0 top-0 justify-center">{<div className=""><IonSpinner/></div>}</div>}
			<div className={`p-8 text-center ${loading ? "opacity-0" : "opacity-100"} transition-all ease-out`}>
				<div className="font-bold">
					Aktuell keine neuen Stellen
				</div>
				<div>Neu laden</div>
			</div>
		</button>}


    </div>
}

function StoreListCard({className = ""}) {
    const matches = useUser(state => state.matches) || []
    const stored = matches.filter(m => m.matchStateSeeker === "STORED")
    if (stored.length === 0) return null
    return <div className={`StoreListCard ${className}`}>
        <div className="p-4 shadow-md rounded-lg">
            <div className="font-bold text-xl">{stored.length} Stellen auf der Merkliste</div>
            <Link to={"/tabs/discover"}>
                <IonButton expand={"block"} fill={"outline"} color={"primary"}>anzeigen</IonButton>
            </Link>
        </div>
    </div>
}

function TrashListCard({className = ""}) {
    const matches = useUser(state => state.matches) || []
    const canceled = matches.filter(m => m.matchStateSeeker && m.matchStateSeeker === "CANCELED")
    if (canceled.length === 0) return null
    return <div className={`StoreListCard ${className}`}>
        <div className="p-4 border-b border-t">
            <div className="font-bold text-xl">{canceled.length} Stellen im Papierkorb</div>
            <Link to={"/tabs/discover"}>
                <IonButton expand={"block"} fill={"outline"} color={"primary"}>anzeigen</IonButton>
            </Link>
        </div>
    </div>
}

interface OwnProps extends RouteComponentProps {
    OwnProps: any;
}


type State = {
    isLoading: boolean,
    isLocked: boolean,
    isMatchModalOpen: boolean,
    isProfileOpen: boolean,
    currentProfile: JobProfile | null,
    showSummary: boolean,
    stack: swing.Stack | null,
    updateFlag: number,
}


class Explore extends React.Component<OwnProps, State> {

    state = {
        isLoading: true,
        isLocked: false,
        isMatchModalOpen: false,
        isProfileOpen: false,
        currentProfile: null,
        stack: null,
        showSummary: true,
        updateFlag: Math.random()
    }

    public constructor(props: OwnProps) {
        super(props);
        this.stackRef = React.createRef();
        this.nopeEl = null;
        this.likeEl = null;
        this.nextCardEl = null;
    }

    handleLogout = () => {
        this.props.history.push('/userprofile');
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        this.setState({isLoading: true});
        const result = await useUser.getState().loadJobProfiles()
        this.setState({isLoading: false, updateFlag: Math.random()}) // side-effect :/
        return result
    }

    getTopCardEl = () => {
        if (this.stackRef) {
            // @ts-ignore
            const {children} = this.stackRef.current;
            let targetEl;

            if (children.length >= 1) {
                targetEl = children[children.length - 1];
            }

            if (targetEl) return targetEl;
        }

        return null;
    }

    getNextCardEl = () => {
        if (this.stackRef) {
            // @ts-ignore
            const {children} = this.stackRef.current;
            let targetEl;

            if (children.length >= 2) {
                targetEl = children[children.length - 2];
            }

            if (targetEl) return targetEl;
        }

        return null;
    }

    checkMatching = (card: any) => {
        if (card.name === 'Jenna Aris') {
            console.info('MATCHED!!!');
            this.handleToggleMatchModal();
        }
    }

    handleSetStack = (swingStack: any) => {
        this.setState({stack: swingStack});

        const topCardEl = this.getTopCardEl();

        if (topCardEl) {
            this.nopeEl = topCardEl.querySelector('.stamp-nope');
            this.likeEl = topCardEl.querySelector('.stamp-like');
        }

        this.nextCardEl = this.getNextCardEl();
    }

    handleButtonClicked = (type: string = 'NOPE', card: JobProfile) => {
        if (this.state.isLocked) return false;

        const {stack} = this.state;

        if (this.stackRef && stack) {
            const topCardEl = this.getTopCardEl();

            if (topCardEl) {

                if (type === "STORE") {
                    card.state = EMatchState.STORED
                    //console.log("STORE", topCardEl)
                }

                if (type === 'NOPE') {
                    card.state = EMatchState.TRASHED
                    //console.log("STORE", topCardEl)
                    if (this.nopeEl) this.nopeEl.style.opacity = '1';
                } else {
                    if (type === 'LIKE' && this.likeEl) this.likeEl.style.opacity = '1';
                }

                setTimeout(() => {
                    // @ts-ignore
                    const card = stack.getCard(topCardEl);
                    const throwX = type === 'NOPE' ? -0.5 * topCardEl.offsetWidth : 0.5 * topCardEl.offsetWidth;
                    card.throwOut(throwX, 20, type === 'NOPE' ? ReactSwing.DIRECTION.LEFT : ReactSwing.DIRECTION.RIGHT);
                }, 500);
            }
        }
    }

    handleClickNope = (card: JobProfile) => {
        this.handleButtonClicked('NOPE', card);
    }

    handleClickSave = (card: JobProfile) => {
        this.handleButtonClicked('STORE', card);
    }

    handleClickLike = (card: JobProfile) => {
        this.handleButtonClicked('LIKE', card);
    }

    handleRefresh = async (e: any) => {
        await this.getData()
        e.detail.complete()
    }

    handleGetMoreCards = () => {
        if (this.state.isLocked) return false;
        // this.getData();
    }

    // Called whenever we drag an element
    handleCardDragging = (element: HTMLElement, x: number, y: number, r: number) => {
        const calculatedValue = Math.min(100, Math.abs(x) - 20) / 100;// 0 <-> 1 for Opacity

        window.requestAnimationFrame(() => {
            element.style.transform = `translate3d(${x}px, ${y}px, 0) rotate(${r}deg)`;
        });

        if (Math.abs(x) > 20 && Math.abs(x) <= element.offsetWidth / 2) {
            window.requestAnimationFrame(() => {
                // @ts-ignore
                this.nopeEl.style.opacity = x < 0 ? calculatedValue : 0;
                // @ts-ignore
                this.likeEl.style.opacity = x < 0 ? 0 : calculatedValue;
            })

            // Zoom effect for the card behind the current one
            window.requestAnimationFrame(() => {
                if (this.nextCardEl) {
                    this.nextCardEl.style.transform = `translate3d(0,0,0) scale(${0.94 + 0.06 * calculatedValue}, ${0.94 + 0.06 * calculatedValue})`;
                }
            });
        } else if (x === 0) {
            window.requestAnimationFrame(() => {
                // @ts-ignore
                this.likeEl.style.opacity = 0;
                // @ts-ignore
                this.nopeEl.style.opacity = 0;
            })
        }
    }

    handleCardThrowOut = (e: any, direction = ReactSwing.DIRECTION.LEFT) => {
        this.setState({isLocked: true});
        const jobs = useUser.getState().newJobProfiles
        const createMatch = useJobMatch.getState().createMatch
        // @ts-ignore
        const jobSeekerId = useUser.getState().session.primarySeekerId
        const removedCard: JobProfile = jobs[jobs.length - 1];

        if (direction === ReactSwing.DIRECTION.RIGHT) {
            this.checkMatching(removedCard);
        }

        const YES = direction === ReactSwing.DIRECTION.RIGHT
        if (YES) {
            // SELECT THE CARD FOR MATCH
            //console.log("MATCH", removedCard)
            createMatch(jobSeekerId, removedCard.id, removedCard.state || EMatchState.REQUESTED)
        } else {
            createMatch(jobSeekerId, removedCard.id, removedCard.state || EMatchState.NONE)
        }
    }

    handleThrowIn = () => {
        this.setState({isLocked: false});
    }

    handleCardThrowOutEnd = () => {
        const cardList = useUser.getState().newJobProfiles
        // let { jobs: cardList } = this.state;

        // Remove the last element
        const newList = cardList.slice(0, cardList.length - 1)
        useUser.getState().setJobProfiles(newList)
        this.setState({
            updateFlag: Math.random()
        }, () => {
            // Scale the top card to its full size
            const topCardEl = this.getTopCardEl();

            if (topCardEl) {
                topCardEl.style.transform = 'scale(1, 1)';
            }

            this.setState({isLocked: false});

            // If there's no more cards left, show Loading screen and load more
            if (newList.length === 0) {
                this.setState({showSummary: true})
                // this.getData();
            }
        });
    }

    handleToggleMatchModal = () => {
        this.setState({
            isMatchModalOpen: !this.state.isMatchModalOpen,
        })
    }

    handleNoMoreSlide = (isOnTheLeft: boolean) => {
        if (this.stackRef && this.stackRef.current) {
            const className = isOnTheLeft ? 'rotate-left' : 'rotate-right';

            // @ts-ignore
            this.stackRef.current.classList.add(className);
            setTimeout(() => {
                // @ts-ignore
                this.stackRef.current.classList.remove(className);
            }, 250);
        }
    }

    handleOpenProfile = (job?: JobProfile) => {
        const jobs = useUser.getState().newJobProfiles
        this.setState({
            isProfileOpen: !this.state.isProfileOpen,
            currentProfile: job || jobs[0],
        })
    }

    handleToggleProfile = () => {
        this.setState({
            isProfileOpen: !this.state.isProfileOpen,
        })
    }

    /**
     Read more: https://github.com/gajus/swing#configuration
     */
    stackConfig = {
        // Default setting only allows UP, LEFT and RIGHT so you can override this as below
        allowedDirections: [
            ReactSwing.DIRECTION.LEFT,
            ReactSwing.DIRECTION.RIGHT
        ],
        throwOutConfidence: (offsetX: number, _offsetY: number, element: HTMLElement) => {
            return Math.min(Math.abs(offsetX) / (element.offsetWidth / 2), 1);
        },
        transform: (element: HTMLElement, x: number, y: number, r: number) => {
            this.handleCardDragging(element, x, y, r);
        },
        throwOutDistance: () => {
            return window.outerWidth * 1.5;
        }
    };


    stackRef: React.RefObject<HTMLDivElement>;
    nopeEl: HTMLElement | null;
    likeEl: HTMLElement | null;
    nextCardEl: HTMLElement | null;

    render() {
        const {isLoading, isMatchModalOpen, isProfileOpen, currentProfile} = this.state;
        const jobs = useUser.getState().newJobProfiles
        const activeCard = jobs[jobs.length - 1]

        return (
            <IonPage>
                <HeaderMain/>
                <IonContent className="explore-bg explore-page" forceOverscroll={true}>
                    <IonRefresher slot="fixed" onIonRefresh={this.handleRefresh}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>
                    {
                        isLoading &&
						<div className="full-height safe-area-bottom">
							<IonRow className="full-height ion-justify-content-center ion-align-items-center">
								<RippleLoader imageUrl="assets/icon/skilled-logo-square.png"/>
							</IonRow>
						</div>
                    }

                    {this.state.showSummary && <div className="">
						<CreateAnimation
							duration={420}
							play
							fromTo={[
                                {property: 'transform', fromValue: 'translateY(-4px)', toValue: 'translateY(0)'},
                                {property: 'opacity', fromValue: '0', toValue: '1'}
                            ]}
						>

							<div className="px-4">
								<JobProfilesCard
									className={"mb-4"}
									jobs={jobs}
									onClick={async () => {
                                        let jobs = await this.getData()

                                        if (jobs.length > 0) this.setState({showSummary: false})
                                    }}
								/>

								<ProfileStatusCard className="mb-4 " to={"/userprofile"}/>
								<MatchStatusCard className="mb-4" to={"/tabs/discover"}/>

								<div className=" pt-4">
									<StoreListCard/>
								</div>

								<div className="px-2">
									<TrashListCard/>
								</div>
							</div>

							<NotificationsList/>

						</CreateAnimation>


					</div>}

                    {
                        (!isLoading && !this.state.showSummary) &&
						<div className="cards-container safe-area-bottom">
                            { /* @ts-ignore: TS2739 */}
							<ReactSwing
								ref={this.stackRef}
								className="card-stack"
								setStack={this.handleSetStack}
								config={this.stackConfig}
								throwin={this.handleThrowIn}
								throwoutleft={(e: any) => this.handleCardThrowOut(e, ReactSwing.DIRECTION.LEFT)}
								throwoutright={(e: any) => this.handleCardThrowOut(e, ReactSwing.DIRECTION.RIGHT)}
								throwoutend={this.handleCardThrowOutEnd}
							>
                                {
                                    jobs
                                        .map((item: JobProfile, index) => (
                                            <div
                                                className={`card-item${index < jobs.length - 2 ? ' ion-hide' : ''}`}
                                                key={item._id} data-card-id={item._id}
                                            >
                                                <SwipeJobCard
                                                    job={item}
                                                    onNoMoreSlide={this.handleNoMoreSlide}
                                                    onViewInfo={(job) => this.handleOpenProfile(job)}
                                                />

                                                <div className="stamp stamp-like"><span className="inline-block px-8">JA</span></div>
                                                <div className="stamp stamp-nope"><span className="inline-block px-8">NEIN</span></div>
                                            </div>
                                        ))
                                }
							</ReactSwing>

                            {!this.state.showSummary && <div className="card-actions">
								<IonGrid>
									<IonRow className="ion-justify-content-center ion-align-items-center">
                                        {activeCard?.match &&
											<IonCol size={"auto"}>
												<Link to="/tabs/discover">
													<IonButton
														fill='outline' color="white" className="">
                                                        {/*<IonIcon slot="icon-only" icon={ trashOutline }/>*/}
														Zum Match
													</IonButton>
												</Link>

											</IonCol>
                                        }

                                        {!activeCard?.match && <>
											<IonCol size="auto">
												<IonButton
													fill='outline' color="white" className="" onClick={() => this.handleClickNope(activeCard)}>Ablehnen</IonButton>
											</IonCol>

                                            {/*<IonCol size="auto">*/}
                                            {/*  <IonButton */}
                                            {/*             fill='outline' color="white" className=""*/}
                                            {/*             onClick={ ()=>this.handleClickNope(activeCard) }>*/}
                                            {/*    ablehnen*/}
                                            {/*  </IonButton>*/}
                                            {/*</IonCol>*/}

											<IonCol size="auto">
												<IonButton
													fill='outline' color="white" className="   " onClick={() => this.handleClickSave(activeCard)}>
													Merken
                                                    {/*<IonIcon icon={ clipboardOutline }/>*/}
												</IonButton>
											</IonCol>
											<IonCol size="auto">
												<IonButton
													fill='outline' color="primary" className=""
													onClick={() => this.handleClickLike(activeCard)}>
													Bewerben
												</IonButton>
											</IonCol>
										</>}
                                        {/*<IonCol size="auto">*/}
                                        {/*  <IonButton fill='outline' color="boost" className="button-custom button-icon button-boost">*/}
                                        {/*    <IonIcon slot="icon-only" icon={ flash }/>*/}
                                        {/*  </IonButton>*/}
                                        {/*</IonCol>*/}
									</IonRow>
								</IonGrid>
							</div>}
						</div>
                    }
                </IonContent>

                <IonModal isOpen={isProfileOpen} canDismiss>
                    <Profile
                        user={currentProfile}
                        onClose={this.handleToggleProfile}
                    />
                </IonModal>
            </IonPage>
        );
    }

};


export default (Explore);
