import create from "zustand";
import api from './api';
import { EMatchState } from '../model/JobProfile';
import useUser from './useUser';

declare module namespace {

    export interface JobProfile {
        _id: string;
        link: string;
        display: string;
    }

    export interface Company {
        _id: string;
        link: string;
        display: string;
    }

    export interface JobSeeker {
        _id: string;
        link: string;
        display: string;
    }

    export interface RootObject {
        jobProfile: JobProfile;
        company: Company;
        matchDate: string;
        jobSeeker: JobSeeker[];
        _mby: string;
        _by: string;
        _modified: number;
        _created: number;
        _id: string;
    }

}


interface JobMatchState {
    firstName: string;
    lastName: string;
    additionalName: string;
    branch: string;
    street: string;
    postcode: string;
    city: string;
    country: string;
    _mby: string;
    _by: string;
    _modified: number;
    _created: number;
    _id: string;
    streetNumber: string;
    email: string;
    setJobSeeker: (data: Partial<JobMatchState>)=>void
    fetchJobSeeker: (jobSeekerId: string)=>void
    createMatch: (jobSeekerId: string, jobProfileId: string, matchState?: EMatchState)=>void
}

const useJobMatch = create<JobMatchState>((set)=>( {
    firstName: "",
    lastName: "",
    additionalName: "",
    branch: "",
    street: "",
    postcode: "",
    city: "",
    country: "",
    _mby: "",
    _by: "",
    _modified: 0,
    _created: 0,
    _id: "",
    streetNumber: "",
    email: "",
    createMatch: async(jobSeekerId, jobProfileId, state = EMatchState.REQUESTED)=>{
        const match = await api<Partial<JobMatchState>>(`/api/jobMatch`, {
            method: "POST",
            data: {
                jobSeekerId,
                jobProfileId,
                state
            }
        })
        //console.log({ match })
        useUser.getState().loadMatches()
        // useJobMatch.setState(seeker)
    },

    fetchJobSeeker: async(jobSeekerId)=>{
        const seeker = await api<Partial<JobMatchState>>(`/api/user/jobSeeker/${ jobSeekerId }`)
        useJobMatch.setState(seeker)
        //console.log({ seeker })
    },

    setJobSeeker: (data)=>{
        set(data)
    }
} ));

export default useJobMatch;
