import React, {FormEvent, useMemo} from 'react';
import {useSwiper} from 'swiper/react';
import {FormInput, FormRadioItem, FormSwitchButton, SlideContent} from '../Onboarding';
import useUser from '../../../store/useUser';
import {IonButton, IonLabel, IonListHeader, IonRadioGroup} from '@ionic/react';
import DatePicker from "components/DatePicker";


const StepDegree: React.FC<{ title?: string }> = ({children, title = ""}) => {
    const swiper = useSwiper()
    const setStepData = useUser(state => state.setStepData)
    const stepData = useUser(state => state.stepData)

    const isAcademic = stepData.careerCurrentDegree === "Bachelor" || stepData.careerCurrentDegree === "Master"
    const isApprentice = stepData.careerCurrentDegree === "Ausbildung" || stepData.careerCurrentDegree === "Sonstiges"
    const isMaster = stepData.careerCurrentDegree === "Meister"
    return <SlideContent title={"Dein Abschluss"} canContinue={stepData.careerCurrentDegree}>
        <div>
            <form className="" onSubmit={(e) => {
                e.preventDefault()
            }}>
                <div className="border-bottom">
                    <IonRadioGroup value={stepData.degreeCurrent} onIonChange={e => setStepData("degreeCurrent", e.detail.value)}>
                        <IonLabel>Dein letzter Abschluss?</IonLabel>
                        <FormSwitchButton size="small" fieldName={"careerCurrentDegree"} value={"Kein Abschluss"}/>
                        <FormSwitchButton size="small" fieldName={"careerCurrentDegree"} value={"Hauptschulabschluss"}/>
                        <FormSwitchButton size="small" fieldName={"careerCurrentDegree"} value={"Realschulabschluss"}/>
                        <FormSwitchButton size="small" fieldName={"careerCurrentDegree"} value={"Fachabitur"}/>
                        <FormSwitchButton size="small" fieldName={"careerCurrentDegree"} value={"Abitur"}/>
                        <FormSwitchButton size="small" fieldName={"careerCurrentDegree"} value={"Bachelor"}/>
                        <FormSwitchButton size="small" fieldName={"careerCurrentDegree"} value={"Master"}/>

                        {/*<FormSwitchButton size="small fieldName={ "careerCurrentDegree" } value={ "Noch in schulischer Ausbildung" }/>*/}
                        <FormSwitchButton size={"small"} fieldName={"careerCurrentDegree"} value={"Ausbildung"}/>
                        <FormSwitchButton size={"small"} fieldName={"careerCurrentDegree"} value={"Meister"}/>
                        <FormSwitchButton size={"small"} fieldName={"careerCurrentDegree"} value={"Sonstiges"}/>

                        {isAcademic && <>
                            <FormInput name={"lastDegreeTitle"} title={"Dein Studiengang"} onChange={setStepData}/>
                        </>}

                        {isApprentice && <>
							<FormInput name={"LastApprenticeshipTitle"} title={"Deine Ausbildung"} onChange={setStepData}/>
						</>}

                        {isMaster && <>
							<FormInput name={"lastDegreeTitle"} title={"Dein Meister"} onChange={setStepData}/>
						</>}

                    </IonRadioGroup>
                </div>

                <FormInput value={stepData.lastEmployerTitle} name={"lastEmployerTitle"} title={"Letzer Arbeitgeber"} onChange={setStepData}/>
                {/*<FormInput name={ "lastJobTitle" } title={ "Dein letzter Job" } onChange={ setStepData }/>*/}
                <div className="w-full">
                    <DatePicker onChange={(e: FormEvent) => {
                        // @ts-ignore
                        setStepData("lastEmployerStartDate", e.detail.value)
                        // @ts-ignore
                        //console.log(e.detail.value)
                    }}>
                    </DatePicker>
                </div>
            </form>
        </div>
    </SlideContent>
}


export default StepDegree