import { useProfileStatus } from '../../store/useUser';
import { IonIcon } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';
import React from 'react';
import { Link } from 'react-router-dom';

export function ProfileStatusCard({
                                      to = "",
                                      onClick = ()=>{
                                      },
                                      className = ""
                                  }) {
    const { status } = useProfileStatus()
    if ( status === 100 ) return null

    return <Link to={ to } >
        <button
            onClick={ onClick }
            className={`text-left bg-yellow-500 p-4 rounded-xl text-white w-full ${className}`}>
            <div className="flex flex-row items-center">
                <span className="flex flex-row items-center pr-2"><IonIcon icon={ personCircleOutline }/></span>
                <div className="text-sm uppercase"> Profil</div>
            </div>

            <div className="text-6xl py-4">
                { status }%
            </div>
            <div>Fülle Dein Profil aus um bessere Matches zu erhalten.</div>
        </button>
    </Link>
}