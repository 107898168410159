import React, { FormEvent } from 'react';
import { useSwiper } from 'swiper/react';
import { FormInput, FormRadioItem, SlideContent } from '../Onboarding';
import useUser from '../../../store/useUser';
import { IonButton, IonLabel, IonListHeader, IonRadioGroup } from '@ionic/react';
import DatePicker from '../../../components/DatePicker';



export const StepTargetJobSectors = () => {
    const setStepData = useUser(state=>state.setStepData)
    const stepData = useUser(state=>state.stepData)
    return <div className="flex flex-col">
        <IonButton onClick={ ()=>setStepData("jobType", "Festanstellung") } fill={ stepData.jobType === "Festanstellung" ? "solid" : `outline` }>Festanstellung</IonButton>
        <IonButton onClick={ ()=>setStepData("jobType", "Mini-Job") } fill={ stepData.jobType === "Mini-Job" ? "solid" : `outline` }>Mini-Job</IonButton>
        <IonButton onClick={ ()=>setStepData("jobType", "Ausbildung") } fill={ stepData.jobType === "Ausbildung" ? "solid" : `outline` }>Ausbildung</IonButton>
        <IonButton onClick={ ()=>setStepData("jobType", "Praktikum") } fill={ stepData.jobType === "Praktikum" ? "solid" : `outline` }>Praktikum</IonButton>
    </div>
}


const StepPersonal: React.FC<{ title?: string }> = ({ children, title = "" })=>{
    const swiper = useSwiper()
    const setStepData = useUser(state=>state.setStepData)
    const stepData = useUser(state=>state.stepData)
    return <SlideContent title={ "Dein Profil" } canContinue={ !!stepData.jobType }>
        <div>

            <IonLabel>Was suchst Du?</IonLabel>
            <StepTargetJobSectors/>

            {/*<div className="pt-8">*/}
            {/*    <IonLabel>Zu wann suchst Du etwas?</IonLabel>*/}
            {/*    <div className="flex flex-col">*/}
            {/*        <IonButton onClick={ ()=>setStepData("careerStartDate", "Egal") } fill={ stepData.careerStartDate === "Egal" ? "solid" : `outline` }>Alle anzeigen</IonButton>*/}
            {/*        <IonButton onClick={ ()=>setStepData("careerStartDate", "Sofort") } fill={ stepData.careerStartDate === "Sofort" ? "solid" : `outline` }>Sofort</IonButton>*/}
            {/*        <IonButton onClick={ ()=>setStepData("careerStartDate", "nächstes Ausbildungsjahr") } fill={ stepData.careerStartDate === "nächstes Ausbildungsjahr" ? "solid" : `outline` }>nächstes Ausbildungsjahr</IonButton>*/}
            {/*        <IonButton onClick={ ()=>setStepData("careerStartDate", "SELECT_DATE") } fill={ stepData.careerStartDate === "SELECT_DATE" ? "solid" : `outline` }>Datum wählen</IonButton>*/}

            {/*        { stepData.careerStartDate === "SELECT_DATE" && <>*/}
            {/*          <DatePicker onChange={ (e: FormEvent)=>{*/}
            {/*              // @ts-ignore*/}
            {/*              setStepData("careerStartDateValue", e.detail.value)*/}
            {/*              // @ts-ignore*/}
            {/*              console.log(e.detail.value)*/}
            {/*          } }>*/}
            {/*          </DatePicker>*/}
            {/*        </> }*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>
    </SlideContent>
}


export default StepPersonal