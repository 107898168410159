import React from 'react';
import {useSwiper, useSwiperSlide} from 'swiper/react';
import {FormInput, SlideContent} from '../Onboarding';
import useUser from '../../../store/useUser';
import {IonButton, IonLabel} from '@ionic/react';
import api from "store/api";


interface IVerifyResponse {
    requested: boolean
    status: boolean
    message: string
}

const StepPhoneVerification: React.FC<{ title?: string }> = ({children, title = ""}) => {
    const swiper = useSwiper()
    const slide = useSwiperSlide()

    const setStepData = useUser(state => state.setStepData)
    const stepData = useUser(state => state.stepData)

    const phone = stepData.phone
    const [loading, setLoading] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const [status, setStatus] = React.useState(false)
    const [requested, setRequested] = React.useState(false)



    async function requestVerificationCode() {
        setLoading(true)

        if(stepData.phone === "+49999"){
            setStatus(true)
            setLoading(false)
            setMessage("Welcome QA-Tester")
            return
        }


        const verify = await api<IVerifyResponse>(`/api/auth/verifyPhone`, {
            mode: "cors",
            data: {
                phone: stepData.phone
            },
            method: "POST"
        })

        //console.log({verify})
        setMessage(verify.message)
        setStatus(verify.status)
        setRequested(verify.requested)
        setLoading(false)
    }

    // useEffect(() => {
    //     if(slide.isActive){
    //         requestVerificationCode()
    //     }
    // }, [phone, slide])

    return <SlideContent title={"Bestätige Dein Konto"} canContinue={status}>
        <div>
            <form className="" onSubmit={() => {
            }}>
                <div>
                    <div className=""><IonLabel>{message}</IonLabel></div>

                    <div>

                        {(!requested) && <div>
							<IonButton expand="block" onClick={requestVerificationCode}>Code senden</IonButton>
						</div>}

                        {/*<VerificationInput/>*/}
                        {!status && <FormInput maxLength={4} value={stepData.verificationCode}
											   name={"verificationCode"}
											   pattern="\d*"
											   title={"Ihr Code"}
											   onChange={async (name: string, value: string, e: any) => {
                                                   if (e.currentTarget.value.length === 4) {



                                                       const verify = await api<IVerifyResponse>(`/api/auth/verifyPhone`, {
                                                           mode: "cors",
                                                           data: {
                                                               code: e.currentTarget.value,
                                                               phone: stepData.phone
                                                           },
                                                           method: "POST"
                                                       })

                                                       //console.log({verify})
                                                       setMessage(verify.message || "Leider ist ein Fehler aufgetreten")
                                                       setRequested(verify.requested)
                                                       setLoading(false)
                                                       setStatus(verify.status)
                                                   }
                                               }
                                               }/>}

                    </div>


                    <div className="text-gray-600 pt-4 p-4 bg-white rounded-xl shadow-md mt-8 text-sm">
                        <div className="uppercase text-xs">Deine Telefon-Nummer:</div>
                        <div className="text-lg font-bold">{stepData.phone}</div>
                        <a href="#" className="font-bold  text-yellow-700 hover:text-yellow-600" onClick={(e)=> {
                            swiper.enable()
                            swiper.slidePrev()
                            e.preventDefault()
                            return false
                        }}>ändern</a>
                    </div>
                </div>
            </form>
        </div>
    </SlideContent>
}


export default StepPhoneVerification